import { VehicleKey } from "@accident-statement/schema";
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useReport, useReportContext } from "../report/ReportContext";
import { useApiClient, ApiClient, HttpVerb } from "./ApiClient";

export const TOKEN_A_HEADER = "X-Token-A";
export const TOKEN_B_HEADER = "X-Token-B";

export function useReportToken(): ReportToken {
  const report = useReport();

  if ("tokenA" in report) {
    return { value: report.tokenA, party: VehicleKey.A };
  } else {
    return { value: report.tokenB, party: VehicleKey.B };
  }
}

export interface ReportToken {
  value: string;
  party: VehicleKey;
}

export function getTokenHeader(token: ReportToken): { [key: string]: string } {
  switch (token.party) {
    case VehicleKey.A:
      return { [TOKEN_A_HEADER]: token.value };
    case VehicleKey.B:
      return { [TOKEN_B_HEADER]: token.value };
    default:
      throw new Error(`Unrecognized party ${token.party}`);
  }
}

interface ReportClient extends ApiClient {
  send<T>(method: HttpVerb, uri: string, content: any): Promise<T>;
}

const ReportClientContext = createContext<ReportClient | null>(null);

export function ReportClientProvider({ children }: { children: ReactNode }) {
  const reportContext = useReportContext();
  const report = reportContext.report;
  const apiClient = useApiClient();

  const tokenA = useMemo(
    () => (report && "tokenA" in report ? report.tokenA : null),
    [report]
  );

  const tokenB = useMemo(
    () => (report && "tokenB" in report ? report.tokenB : null),
    [report]
  );

  const tokenHeader = useMemo(() => {
    if (tokenA) return getTokenHeader({ value: tokenA, party: VehicleKey.A });
    if (tokenB) return getTokenHeader({ value: tokenB, party: VehicleKey.B });
    return null;
  }, [tokenA, tokenB]);

  const send = useCallback(
    function send<T>(method: HttpVerb, uri: string, content?: any): Promise<T> {
      const headers = { ...tokenHeader } as { [key: string]: string };
      return apiClient.send<T>(method, uri, content, headers);
    },
    [apiClient, tokenHeader]
  );

  const client = useMemo(
    () => ({
      send,
      get: <T extends unknown>(uri: string) => send<T>("GET", uri),
      post: <T extends unknown>(uri: string, content: any) =>
        send<T>("POST", uri, content),
      put: <T extends unknown>(uri: string, content: any) =>
        send<T>("PUT", uri, content),
      patch: <T extends unknown>(uri: string, content: any) =>
        send<T>("PATCH", uri, content),
      delete: <T extends unknown>(uri: string) => send<T>("DELETE", uri),
    }),
    [send]
  );

  return (
    <ReportClientContext.Provider value={client}>
      {children}
    </ReportClientContext.Provider>
  );
}

export function useReportClient() {
  const context = useContext(ReportClientContext);

  if (!context) throw new Error("No ReportClientContext detected!");

  return context;
}
