import React from "react";
import styled, { css } from "styled-components";
import { MenuListItem } from "@teamscheme/header-elements";
import FlyoutModal, { ModalProps } from "@teamscheme/flyout-modal";
import {
  ButtonLink,
  ButtonLinkVariant,
  ExternalButtonLink,
} from "@teamscheme/button-link";
import { onDesktop } from "@teamscheme/theme";

const Icon = styled.div``;
const Label = styled.div``;

const ModalTitle = styled.h1`
  margin: 0 0 0 ${({ theme }) => theme.spacing.space40};
  ${onDesktop`
    margin: 0
  `}
`;

export default styled(function FlyoutMenu({
  menuItems,
  itemOnClick,
  ...props
}: Omit<ModalProps, "children"> & {
  menuItems: MenuListItem[];
  itemOnClick: () => void;
}) {
  menuItems.map((item) => item);
  return (
    <FlyoutModal {...props}>
      <ModalTitle>Meny</ModalTitle>
      <nav>
        <ul>
          {menuItems.map((item) => {
            const { trackEvent, onClick } = item;
            const variant: ButtonLinkVariant = "secondary";
            const children = <span>{item.text}</span>;
            const base = { variant, trackEvent, children };
            let button = null;
            if ("linkTo" in item && item.linkTo) {
              button = (
                <ButtonLink
                  {...base}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    if (onClick) onClick(e);
                    itemOnClick();
                  }}
                  linkTo={item.linkTo!}
                />
              );
            } else if ("href" in item && item.href) {
              button = (
                <ExternalButtonLink
                  {...base}
                  href={item.href!}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    if (onClick) onClick(e);
                    itemOnClick();
                  }}
                />
              );
            } else if (item.onClick) {
              button = (
                <ButtonLink
                  {...base}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    if (onClick) onClick(e);
                    itemOnClick();
                  }}
                />
              );
            }
            return (
              <li key={item.key}>
                <Icon>{item.icon?.icon}</Icon>
                <Label>{button}</Label>
              </li>
            );
          })}
        </ul>
      </nav>
    </FlyoutModal>
  );
})`
  ${({ theme }) => css`
    nav ul {
      list-style-type: none;
      ${onDesktop`
        padding-inline-start: 0;
      `}

      li {
        padding: 0;
        margin-bottom: ${theme.spacing.space16};
        display: flex;
        align-items: center;

        ${Icon} {
          width: ${theme.spacing.space40};
          display: flex;
          align-items: center;
          jusify-content: flex-start;
        }
      }
    }
  `}
`;
