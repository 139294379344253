import React, { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import LabeledInputComponent from "./LabeledInputComponent";
import ErrorMessage from "../../elements/ErrorMessage";

type LabeledInputProps = {
  name: string;
  label: ReactNode;
  description?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  error?: string;
  hasError?: boolean;
  className?: string;
};

export const LabeledCheckboxComponent = styled(LabeledInputComponent)``;

const LabeledInput = forwardRef<HTMLInputElement, LabeledInputProps>(function (
  {
    name,
    label,
    description,
    inputProps,
    error,
    hasError,
    className,
    ...props
  },
  ref
) {
  const Component =
    inputProps?.type === "checkbox"
      ? LabeledCheckboxComponent
      : LabeledInputComponent;

  const { value, ...restInputProps } = inputProps || {};

  return (
    <Component
      {...props}
      name={name}
      label={label}
      description={description}
      className={className}
      hasError={!!error || hasError}
    >
      <input {...restInputProps} value={value || ""} ref={ref} name={name} />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Component>
  );
});

export default LabeledInput;
