import {
  useState,
  ReactNode,
  useContext,
  createContext,
  useCallback,
} from "react";
import { ProgressBarContext } from "./ProgressBarContext";
import { ApiError } from "../../../api/ApiClient";

type Reset = () => void;

export interface IHeaderWithProgressContext {
  isSaving: boolean;
  setIsSaving(): Reset;
  hasSaved: boolean;
  setHasSaved(hasSaved: boolean): void;
  saveError: ApiError | null;
  setSaveError(saveError: ApiError | null): void;
  progress: number;
}

export const HeaderWithProgressContext =
  createContext<IHeaderWithProgressContext | null>(null);

export function useHeaderWithProgressContext() {
  const headerWithProgressContext = useContext(HeaderWithProgressContext);

  if (!headerWithProgressContext)
    throw new Error("No HeaderWithProgressContext found!");

  return headerWithProgressContext;
}

class SavingFlag {}

export function HeaderWithProgressProvider({
  children,
}: {
  children: ReactNode;
}) {
  const defaultProgress = 1;

  const [savingFlags, setSavingFlags] = useState<SavingFlag[]>([]);
  const [progress, setProgress] = useState(defaultProgress);
  const [hasSaved, setHasSaved] = useState(false);
  const [saveError, setSaveError] = useState<ApiError | null>(null);

  const setIsSaving = useCallback(() => {
    const flag = new SavingFlag();

    setSavingFlags((prev) => [...prev, flag]);

    const reset: Reset = () => {
      setSavingFlags((prev) => prev.filter((x) => x !== flag));
    };

    return reset;
  }, []);

  const isSaving = savingFlags.length > 0;

  return (
    <HeaderWithProgressContext.Provider
      value={{
        isSaving,
        setIsSaving,
        hasSaved,
        setHasSaved,
        saveError,
        setSaveError,
        progress,
      }}
    >
      <ProgressBarContext.Provider value={{ setProgress }}>
        {children}
      </ProgressBarContext.Provider>
    </HeaderWithProgressContext.Provider>
  );
}
