import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { onDesktop } from "@teamscheme/theme";
import FlyoutModal from "@teamscheme/flyout-modal";
import { AccidentReportPage } from "@accident-statement/schema";
import ReportPreview, {
  useReportPreviewClient,
} from "../../../report/ReportPreview";
import { EVENTS, useTracking } from "../../Tracking";

export interface PreviewModalProps {
  isOpen: boolean;
  onClose(): void;
  page: AccidentReportPage;
}

const PreviewMenuModal = styled(FlyoutModal)`
  width: 100%;
`;

const ModalReportPreview = styled(ReportPreview)`
  ${({ theme }) => css`
    width: auto;
    max-width: initial;
    margin: 0 -${theme.spacing.space24};

    ${onDesktop`
      margin: 0 -${theme.spacing.space48};
    `}

    .DocumentPreview {
      box-shadow: none;
      border: 1px solid ${theme.nafColor.neutral.neutral2};
    }
  `}
`;

function LoadDocumentPreview({
  page,
  isOpen,
  onClose,
}: {
  page: AccidentReportPage;
  isOpen: boolean;
  onClose: () => void;
}) {
  const context = useReportPreviewClient()[page];

  const { fetch: load } = context;
  useEffect(() => {
    if (isOpen) load();
  }, [isOpen, load]);

  const { event: trackEvent } = useTracking();
  useEffect(() => {
    if (isOpen) {
      trackEvent({ ...EVENTS.HEADER.PREVIEW, label: page });
    }
  }, [isOpen, trackEvent, page]);

  return <ModalReportPreview preview={context} />;
}

export default function PreviewModal({
  isOpen,
  onClose,
  page,
}: PreviewModalProps) {
  return (
    <PreviewMenuModal isOpen={isOpen} onClose={onClose}>
      <LoadDocumentPreview page={page} isOpen={isOpen} onClose={onClose} />
    </PreviewMenuModal>
  );
}
