import { DateString, VehicleKey } from './AccidentReport';

export enum AssociatedAccidentStatementStatus {
    Signed = 'Signed',
    Started = 'Started',
}
export interface OneTimeCodeResponse {
    accidentStatements: AssociatedAccidentStatementViewModel[];
    token: string;
}
export interface TokenResponse {
    accidentStatements: AssociatedAccidentStatementViewModel[];
}
export interface AssociatedAccidentStatementViewModel {
    url: string;
    county?: string;
    country?: string;
    accidentDate: DateString;
    status: AssociatedAccidentStatementStatus;
    vehicleKey: VehicleKey;
}
