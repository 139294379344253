import styled, { css } from "styled-components";

export type InputComponentProps = {
  hasError?: boolean;
};

const InputComponent = styled.div<InputComponentProps>`
  textarea,
  input[type="text"],
  input[type="search"],
  input[type="email"],
  input[type="url"],
  input[type="tel"],
  input[type="date"],
  input[type="time"],
  input[type="number"],
  select {
    font-variant-numeric: lining-nums;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.spacing.space8};
    border: 1px solid ${({ theme }) => theme.textField.border};
    transition: box-shadow 0.1s ease;
    font-size: 1em;
    line-height: normal;
    border-radius: ${({ theme }) => theme.radius.s};
    min-height: ${({ theme }) => theme.spacing.space48};
    ${({ theme }) => theme.fontStyle.article.body};
  }

  input[type="radio"] {
    transition: box-shadow 0.1s ease;
  }

  textarea:focus,
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="search"]:focus,
  input[type="url"]:focus,
  input[type="tel"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus,
  select:focus {
    outline: 0;
    border: 2px solid ${({ theme }) => theme.textField.focus.border};
    padding: calc(${({ theme }) => theme.spacing.space8} - 1px);
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      textarea,
      input[type="text"],
      input[type="search"],
      input[type="email"],
      input[type="url"],
      input[type="tel"],
      .react-tel-input input[type="tel"],
      input[type="date"],
      input[type="time"],
      input[type="number"],
      select {
        border: 2px solid ${theme.textField.error};
        padding: calc(${theme.spacing.space8} - 1px);
      }
    `}

  select:focus::-moz-focus-inner,
  input[type="checkbox"]:focus::-moz-focus-inner {
    border: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 6"><g><polygon points="14,0 26,0 20,6" fill="black" /></g></svg>');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 15%;
  }

  select::-ms-expand {
    display: none;
  }
`;

export default InputComponent;
