import React, { ImgHTMLAttributes } from "react";
import styled from "styled-components";

type NafLogoProps = {
  size?: number;
};

const NafLogo = styled(
  ({
    className,
    size = 64,
    ...props
  }: NafLogoProps & ImgHTMLAttributes<HTMLImageElement>) => {
    return (
      <img
        width={size}
        height={size}
        {...props}
        src="/graphics/logos/naf-logo_128x128.png"
        alt="NAF"
        className={["NafLogo", className].filter((c) => c).join(" ")}
      />
    );
  }
)``;

export default NafLogo;
