import { DateString, VehicleKey } from "./AccidentReport";
import { CommonSection, PageTwoSection, VehicleSection } from "./Schema";

export type FieldValue =
  | { decimalValue?: number }
  | { stringValue?: string }
  | { dateTimeValue?: DateString }
  | { intValue?: number }
  | { boolValue?: boolean }
  | { stringListValue?: string[] };

export enum SectionParent {
  Vehicle = "Vehicle",
  PageTwo = "PageTwo",
  Common = "Common",
}

interface ReportFieldBase {
  fieldName: string;
}

interface PartyReportFieldBase extends ReportFieldBase {
  party: VehicleKey;
}

interface VehicleReportField extends PartyReportFieldBase {
  parent: SectionParent.Vehicle;
  sectionName: VehicleSection;
}

interface PageTwoReportField extends PartyReportFieldBase {
  parent: SectionParent.PageTwo;
  sectionName: PageTwoSection;
}

interface CommonReportField extends ReportFieldBase {
  parent: SectionParent.Common;
  sectionName: CommonSection;
}

export type ReportField = VehicleReportField | PageTwoReportField | CommonReportField;

export interface ReportUpdate {
  date: DateString;
  reportField: ReportField;
  value: FieldValue;
  updatedBy: VehicleKey;
}
