import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
  createContext,
} from "react";
import { addBreadcrumb } from "@sentry/browser";
import { Deferred } from "./Deferred";

const API_URL = "/api";

export const SESSION_ID_HEADER = "X-Session-Id";

export const ApiClientContext = createContext<{
  client: ApiClient;
  sessionId: string | null;
} | null>(null);

export class ApiError extends Error {
  constructor(message: string, public result: unknown, public status: number) {
    super(message);
  }
}

export type HttpVerb = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export interface ApiClient {
  send<T>(
    method: HttpVerb,
    uri: string,
    content: any,
    headers?: { [key: string]: string }
  ): Promise<T>;
  get<T>(uri: string): Promise<T>;
  post<T>(uri: string, content: any): Promise<T>;
  put<T>(uri: string, content: any): Promise<T>;
  patch<T>(uri: string, content: any): Promise<T>;
  delete<T>(uri: string): Promise<T>;
}

export const ApiClientProvider = ({
  children,
  baseUrl = API_URL,
}: {
  children: React.ReactNode;
  baseUrl?: string;
}) => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const internalSessionId = useRef<Deferred<string>>();
  const doFetch = useCallback(
    (uri: string, init: RequestInit) => {
      addBreadcrumb({
        category: "FetchData",
        data: {
          uri,
          init,
        },
      });
      return fetch(`${baseUrl}/${uri}`, init);
    },
    [baseUrl]
  );

  const send = useCallback(
    async (
      method: HttpVerb,
      uri: string,
      content?: any,
      headers?: { [key: string]: string }
    ) => {
      let currentSessionId = null;
      if (!internalSessionId.current) {
        const deferred: Partial<Deferred<string>> = {};
        deferred.promise = new Promise((resolve, reject) => {
          deferred.resolve = resolve;
          deferred.reject = reject;
        });
        internalSessionId.current = deferred as Deferred<string>;
      } else {
        currentSessionId = await internalSessionId.current.promise;
      }

      const allHeaders = {
        ...headers,
        ...(currentSessionId
          ? { [SESSION_ID_HEADER]: currentSessionId }
          : null),
      };

      const init =
        content !== undefined
          ? {
              method,
              headers: {
                "content-type": "application/json",
                ...allHeaders,
              },
              body: content ? JSON.stringify(content) : undefined,
            }
          : { method, headers: allHeaders };

      const response = await doFetch(uri, init);

      // Update session ID after the response has been returned to the caller
      const nextSessionId = response.headers.get(SESSION_ID_HEADER);
      if (nextSessionId) {
        setSessionId(nextSessionId);

        if (internalSessionId.current.resolve)
          internalSessionId.current.resolve(nextSessionId);
      }

      if (response.status === 404 && method === "GET") return null;

      const contentType = response.headers.get("content-type");

      const result =
        contentType && contentType.indexOf("application/json") !== -1
          ? await response.json()
          : await response.text();

      if (response.status.toString()[0] !== "2") {
        const error = new ApiError(
          `Status code is not OK: ${response.statusText}. ${
            result.error || ""
          }`,
          result,
          response.status
        );

        throw error;
      }

      return result;
    },
    [doFetch]
  );

  const client = useMemo(
    () => ({
      send,
      get: (uri: string) => send("GET", uri),
      post: (uri: string, content: any) => send("POST", uri, content),
      put: (uri: string, content: any) => send("PUT", uri, content),
      patch: (uri: string, content: any) => send("PATCH", uri, content),
      delete: (uri: string) => send("DELETE", uri),
    }),
    [send]
  );

  const context = useMemo(
    () => ({
      client,
      sessionId,
    }),
    [client, sessionId]
  );

  return (
    <ApiClientContext.Provider value={context}>
      {children}
    </ApiClientContext.Provider>
  );
};

export function useApiClient() {
  const context = useContext(ApiClientContext);

  if (!context) throw new Error("No ApiClientContext found!");

  const { client } = context;

  return client;
}

export function useSessionId() {
  const context = useContext(ApiClientContext);

  if (!context) throw new Error("No ApiClientContext found!");

  const { sessionId } = context;

  return sessionId;
}
