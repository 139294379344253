import React, {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useContext,
  useCallback,
  HTMLAttributes,
  ButtonHTMLAttributes,
} from "react";
import { useParty, useReport } from "./ReportContext";
import { Button } from "@teamscheme/button";
import { useLoadOncePerLocation } from "../shared/utils/useLoadOncePerLocation";
import LoadingText from "@teamscheme/loading-text";
import ErrorMessage from "../shared/elements/ErrorMessage";
import styled from "styled-components";
import ZoomableScrollImage from "../shared/elements/ZoomableImage";
import { TemporaryUrl } from "./page-one/attachments/AttachmentsClient";
import { useClientBase, ActionClient } from "../api/ClientBase";
import { useReportClient } from "../api/ReportClient";
import {
  AccidentReportPage,
  AccidentReportViewModel,
  VehicleKey,
} from "@accident-statement/schema";
import MagnifyingGlassSvg from "../graphics/icons/magnifying-glass-plus.svg";

function useDocumentPreviewClient(
  page: AccidentReportPage
): ActionClient<void, PublicDocumentPreviewViewModel> {
  const { id } = useReport();
  const client = useReportClient();
  const clientBase = useClientBase<void, PublicDocumentPreviewViewModel>(
    useCallback(
      () => client.get(`report/${id}/${page}/document/preview`),
      [client, id, page]
    )
  );

  return clientBase;
}

interface ReportPreviewPageContext
  extends ActionClient<void, PublicDocumentPreviewViewModel> {
  loadOncePerLocation: () => void;
}

type IReportPreviewContext = {
  [page in AccidentReportPage]: ReportPreviewPageContext;
};

const ReportPreviewContext = createContext<IReportPreviewContext | null>(null);

function useAutoLoadedDocumentPreview(
  context: ActionClient<void, PublicDocumentPreviewViewModel>,
  getHash: (report: AccidentReportViewModel, party: VehicleKey) => string
): ReportPreviewPageContext {
  const { load, loadedLocation } = useLoadOncePerLocation();
  const { fetch: fetchPreview } = context;
  const report = useReport();
  const party = useParty();
  const documentHash = getHash(report, party);

  useEffect(() => {
    if (!loadedLocation) return;

    const cancelable = fetchPreview();

    return () => cancelable?.cancel();
  }, [fetchPreview, loadedLocation, documentHash]);

  return { ...context, loadOncePerLocation: load };
}

export function ReportPreviewProvider({ children }: { children: ReactNode }) {
  const pageOneContext = useAutoLoadedDocumentPreview(
    useDocumentPreviewClient(AccidentReportPage.PageOne),
    (x) => x.documentHash
  );
  const pageTwoContext = useAutoLoadedDocumentPreview(
    useDocumentPreviewClient(AccidentReportPage.PageTwo),
    (x) => ("pageTwoA" in x ? x.pageTwoA.documentHash : x.pageTwoB.documentHash)
  );

  return (
    <ReportPreviewContext.Provider
      value={{
        [AccidentReportPage.PageOne]: pageOneContext,
        [AccidentReportPage.PageTwo]: pageTwoContext,
      }}
    >
      {children}
    </ReportPreviewContext.Provider>
  );
}

export function useReportPreviewClient() {
  const context = useContext(ReportPreviewContext);
  if (!context) throw new Error("No ReportPreviewContext found!");
  return context;
}

export function useReportPageOnePreview() {
  const context = useReportPreviewClient()[AccidentReportPage.PageOne];

  const { loadOncePerLocation } = context;

  useEffect(() => {
    loadOncePerLocation();
  }, [loadOncePerLocation]);

  return context;
}

interface PublicDocumentPreviewViewModel {
  hash: string;
  url: TemporaryUrl;
}

const DocumentPreviewContainer = styled.div`
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
`;

const DocumentPreviewPlaceholder = styled.div`
  background: white;
  flex: 1 0 auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 4em 1em 1em;
  text-align: center;
`;

const DocumentPreview = styled(ZoomableScrollImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 1;
`;

export type ReportPreviewClient = ActionClient<
  void,
  PublicDocumentPreviewViewModel
>;
export interface ReportPreviewProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onClick" | "children"> {
  preview: ReportPreviewClient;
}

const CompactReportPreviewContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 720px;
  padding-top: ${((8 * 31) / 720) * 100}%;
  overflow: hidden;
`;

const Zoom = styled.div`
  padding: ${({ theme }) => theme.spacing.space24}
    ${({ theme }) => theme.spacing.space32};
  border-radius: ${({ theme }) => theme.radius.s};
  background: white;
  display: flex;
  align-items: center;
  transition: box-shadow 0.25s ease;
  ${({ theme }) => theme.fontStyle.ui.button};

  img {
    width: 1.5em;
    height: 1.5em;
    margin-right: 1em;
  }
`;

const Overlay = styled(function (
  props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children">
) {
  return (
    <button {...props}>
      <Zoom>
        <img alt="Zoom" src={MagnifyingGlassSvg} />
        <span>Forhåndsvis</span>
      </Zoom>
    </button>
  );
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: zoom-in;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

export function CompactReportPreview(props: ReportPreviewProps) {
  const { preview, ...divProps } = props;
  const { isLoading, result } = preview;
  const changed = false;
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <div {...divProps}>
      <CompactReportPreviewContainer>
        {isLoading ? (
          <DocumentPreviewPlaceholder>
            <LoadingText
              text={
                !changed
                  ? "Henter forhåndsvisning"
                  : `Din motpart har oppdatert sin del av skademeldingen. Henter ny forhåndsvisning`
              }
            />
          </DocumentPreviewPlaceholder>
        ) : null}
        {result ? (
          <>
            <Overlay onClick={() => setIsZoomed(true)} />
            <DocumentPreview
              alt=""
              src={result.url.href}
              isZoomed={isZoomed}
              setIsZoomed={setIsZoomed}
            />
          </>
        ) : preview.error && !preview.isLoading ? (
          <DocumentPreviewPlaceholder>
            <ErrorMessage>
              Kunne ikke laste forhåndsvisning.{" "}
              <Button onClick={() => preview.fetch()}>Prøv igjen</Button>
            </ErrorMessage>
          </DocumentPreviewPlaceholder>
        ) : null}
      </CompactReportPreviewContainer>
    </div>
  );
}

export default function ReportPreview(props: ReportPreviewProps) {
  const { preview, ...divProps } = props;
  const { isLoading, result } = preview;
  const signed = false;
  const changed = false;
  const [imageDimensions, setImageDimesions] = useState<{
    height: number;
    width: number;
  } | null>(null);
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <div {...divProps}>
      <DocumentPreviewContainer
        style={{
          paddingTop: imageDimensions
            ? `${(imageDimensions.height / imageDimensions.width) * 100}%`
            : `${Math.sqrt(2) * (signed ? 2 : 1) * 100}%`,
        }}
      >
        {isLoading || !imageDimensions ? (
          <DocumentPreviewPlaceholder>
            <LoadingText
              text={
                !changed
                  ? "Henter forhåndsvisning"
                  : `Din motpart har oppdatert sin del av skademeldingen. Henter ny forhåndsvisning`
              }
            />
          </DocumentPreviewPlaceholder>
        ) : null}
        {result ? (
          <DocumentPreview
            onLoad={(event) => {
              const { height, width } = (
                event.target as HTMLImageElement
              ).getBoundingClientRect();

              setImageDimesions({ height, width });
            }}
            alt=""
            src={result.url.href}
            isZoomed={isZoomed}
            setIsZoomed={setIsZoomed}
            onClick={() => setIsZoomed(true)}
          />
        ) : preview.error && !preview.isLoading ? (
          <DocumentPreviewPlaceholder>
            <ErrorMessage>
              Kunne ikke laste forhåndsvisning.{" "}
              <Button onClick={() => preview.fetch()}>Prøv igjen</Button>
            </ErrorMessage>
          </DocumentPreviewPlaceholder>
        ) : null}
      </DocumentPreviewContainer>
    </div>
  );
}
