import React, { useState, useEffect, useRef } from "react";
import queryString from "query-string";
import { useTracking, EVENTS } from "../shared/Tracking";
import styled from "styled-components";
import { Accordion, normalizedAccordionId } from "@teamscheme/accordion";
import { useLocation } from "react-router-dom";
import {
  normalizeComponentTree,
  search,
  useDebouncedQuery,
  FilterField,
  NoResultsPlaceholder,
} from "../shared/search/Filter";
import { useDocumentTitle, TITLE_POSTFIX } from "../shared/DocumentTitle";
import FormGroup from "../shared/forms/FormGroup";
import { AboutContent } from "../shared/static-pages/StaticPage";
import StaticHeroPage from "../shared/static-pages/StaticHeroPage";
import Card from "../shared/layout/Card";
import StaticRoutes from "../shared/static-pages/StaticRoutes";

interface FaqItem {
  question: string;
  answer: JSX.Element;
  top?: boolean;
}

interface FaqSection {
  title: string;
  items: FaqItem[];
}
const sections: FaqSection[] = [
  {
    title: "Generelt om skademelding",
    items: [
      {
        top: true,
        question: "Hva er en skademelding?",
        answer: (
          <span>
            Skademeldingen er et skjema som skal fylles ut etter en kollisjon,
            og er et viktig dokument ved vurdering av ansvar etter en
            bilkollisjon. Ved hjelp av skademeldingen kan forsikringsselskapet
            finne ut av hva som skjedde og overføre ansvaret på ansvarlig part.
          </span>
        ),
      },
      {
        top: true,
        question: "Hvorfor bør jeg skrive skademelding?",
        answer: (
          <span>
            Det er anbefalt å skrive skademelding i fellesskap umiddelbart etter
            uhellet. Da har dere hendelsen friskt i minnet, og kan dokumentere
            fra skadestedet. Ved hjelp av skademeldingen kan
            forsikringsselskapet finne ut av hva som skjedde og overføre
            ansvaret på ansvarlig part.
          </span>
        ),
      },
      {
        question: "Er det lovpålagt å skrive skademelding etter en kollisjon?",
        answer: (
          <span>
            Dere er ikke forpliktet til å fylle ut en felles skademelding. Men
            det lønner seg å fylle ut den ut, aller helst i fellesskap på
            stedet, for raskere saksbehandling hos forsikringsselskapet.
          </span>
        ),
      },
      {
        question: "Finnes NAF Digital skademelding på engelsk?",
        answer: (
          <span>
            "NAF Digital skademelding kommer kun i norsk utgave. Men
            skademeldingens ulike punkter og oppbygning er internasjonal og lik
            på alle språk. Har du kollidert med en utenlandsk bil eller sjåfør,
            eller er i utlandet kan dere trygt fylle ut skjemaet i fellesskap
            selv om dere ikke nødvendigvis forstår hverandre."
          </span>
        ),
      },
      {
        question:
          "Blir skademeldingen sendt direkte til mitt forsikringsselskap når vi har signert?",
        answer: (
          <span>
            Forsikringstaker for kjøretøyet er selv ansvarlig for at utfylt og
            signert skademelding og vedlegg blir sendt til forsikringsselskapet.
            Etter skademeldingen er fylt ut og signert av begge parter får man
            bekreftelse tilsendt på e-post. Skademeldingen og eventuelle vedlegg
            kan lastes ned fra bekreftelsessiden.
          </span>
        ),
      },
      {
        question:
          "Jeg har kollidert med en trafikant eller en annens eiendom som ikke er et motorisert kjøretøy, skal jeg fortsatt fylle ut skademeldingsskjemaet?",
        answer: (
          <span>
            Skademeldingsskjema bør alltid fylles ut når du kolliderer med
            andre, eller en annens eiendom. Også når det forårsaker skader på
            noe annet enn et motorisert kjøretøy f.eks. Sykkel eller
            sparkesykkel. Har det skjedd materielle skader eller personskader
            skal også dette noteres og meldes i fra til forsikringsselskapet
            slik at ansvarsfordelingen kan gå riktig for seg.
            <br />
            <br />
            Husk at personskader også skal meldes til politiet.{" "}
          </span>
        ),
      },
    ],
  },
  {
    title: "Utfylling av skademelding",
    items: [
      {
        question: "Hvor nøye må vi være med utfyllingen?",
        answer: (
          <span>
            Vær ærlige og nøye med utfyllingen. Dersom ukorrekte opplysninger
            blir gitt til forsikringsselskapet om hendelsen kan det i verste
            fall føre til at forsikringsselskapet avslår hele saken.
          </span>
        ),
      },
      {
        top: true,
        question: "Motparten ønsker ikke å samarbeide, hva gjør jeg?",
        answer: (
          <span>
            Hvis motparten ikke ønsker eller har mulighet til å samarbeide bør
            du fylle ut skademelding uansett. Signer og send til ditt
            forsikringsselskap. Pass på å notere motpartens navn, telefonnummer,
            bilens reg.nr, og en erkjennelse av at uhellet har funnet sted.
          </span>
        ),
      },
      {
        top: true,
        question:
          "Hva gjør jeg hvis jeg ikke har informasjon om forsikringen tilgjengelig?",
        answer: (
          <span>
            Hvis du ikke har opplysninger om kjøretøyets forsikring
            tilgjengelig, kan du trygt gå videre i skjemaet uten å fylle ut
            denne informasjonen. Dette kan føre til noe lenger behandlingstid av
            saken, og kreve mer korrespondanse mellom deg og saksbehandler.
          </span>
        ),
      },
      {
        question: "Må jeg tegne skissen?",
        answer: (
          <span>
            Skissen (tegningen) på side 1 av skademeldingen spiller en sentral
            rolle ved ansvarsvurderingen, men bilder fra skadestedet kan være
            vel så god dokumentasjon. Alternativt kan dere også velge å tegne en
            skisse på papir som dere tar bilde av og laster opp som vedlegg.
          </span>
        ),
      },
      {
        question: "Kan jeg endre det jeg har lagt inn?",
        answer: (
          <span>
            Ja, du kan gå tilbake ved å trykke «forrige» øverst til venstre.
            Dette er mulig inntil dere har signert. Forhåndsvisningen oppdateres
            automatisk når du gjør endringer.
          </span>
        ),
      },
    ],
  },
  {
    title: "Inviter motpart",
    items: [
      {
        question:
          "Hva gjør jeg når motparten ikke mottar SMS med invitasjon til utfylling?",
        answer: (
          <span>
            Sjekk at mottakers inntastede mobilnummer riktig og prøv på nytt.
          </span>
        ),
      },
    ],
  },
  {
    title: "Signering",
    items: [
      {
        question: "Hva er InkSign?",
        answer: (
          <span>
            InkSign er den enkleste metoden for å signerer dokumentene. Partene
            bruker fingeren for å signere på skjermen på smarttelefon, eller
            musepekeren på MAC eller PC. Dette er en god løsning ved fysisk
            tilstedeværelse av begge parter hvor man i tillegg kan gjøre en
            sjekk om signaturen matcher motparten sin ID som bankkort/pass.
          </span>
        ),
      },
      {
        question:
          "Jeg venter på motparten sin signatur. Kan jeg lukke nettleseren?",
        answer: (
          <span>
            "Ja, du kan lukke nettleseren. For å finne tilbake til skjemaet
            bruker du den personlige lenken du fikk tilsendt på SMS.
          </span>
        ),
      },
      {
        question:
          "Det står at jeg venter på signatur fra motparten. Hva gjør jeg?",
        answer: (
          <span>
            Du må be motparten signere. Motpart gjør dette ved hjelp av sin
            personlige lenke som ble sendt på SMS.
          </span>
        ),
      },
      {
        question: "Vi har signert, hvor finner jeg skademeldingen?",
        answer: (
          <span>
            Du kommer til en bekreftelsesside når begge parter har signert. Her
            kan du laste ned skademeldingen og eventuelle vedlegg. Du kan nå
            denne siden med den personlige lenken du fikk på SMS. Du får også en
            e-post med skademeldingen vedlagt.
          </span>
        ),
      },
      {
        question:
          "Vi oppdaget en feil i skademeldingen etter at en av oss har signert, hva gjør vi?",
        answer: (
          <span>
            Så lenge ikke begge har signert skademeldingen kan dere avbryte
            signeringen for å åpne skademeldingen for endringer igjen.
            Signaturen blir da forkastet og dere kan prøve igjen. Hvis begge har
            signert er det ikke mulig å gjøre endringer lenger.
          </span>
        ),
      },
      {
        question:
          "Er det mulig å endre skademeldingen etter at den er signert?",
        answer: <span>Nei.</span>,
      },
    ],
  },
];

const FaqWrapper = styled.div`
  section {
    margin-bottom: 3em;
  }
  section h2 {
    ${({ theme }) => theme.fontStyle.headers.header2}
  }
`;

const Answer = styled.p`
  ${({ theme }) => theme.fontStyle.article.body}
  margin-top: 0;
`;

export function TopFaq() {
  const tracking = useTracking();
  const items = sections.map(({ items }) => items.filter((i) => i.top)).flat();

  const [openedId, setOpenedId] = useState(null);

  return (
    <FaqWrapper>
      <Accordion>
        <section>
          {items.map((item, index) => (
            <Accordion.Row
              id={index}
              title={item.question}
              key={index}
              open={openedId === index}
              onOpen={() => {
                if (openedId !== index) {
                  setOpenedId(null);
                  tracking.event({
                    ...EVENTS.LANDING_PAGE.OPEN_QUESTION,
                    label: item.question,
                  });
                }
              }}
            >
              <Answer>{item.answer}</Answer>
            </Accordion.Row>
          ))}
        </section>
      </Accordion>
    </FaqWrapper>
  );
}

interface FaqProps {
  sections: FaqSection[];
  openAll?: boolean;
  openedId?: string;
  onOpen: (id: string) => void;
  query?: string;
}

function Faq({
  sections,
  openAll,
  openedId: initialOpenedId,
  onOpen,
  query,
}: FaqProps) {
  const tracking = useTracking();
  const titles = useRef<HTMLHeadingElement[]>([]);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const timeout = setTimeout(() => {
        const match = titles.current.filter((x) => `#${x.id}` === hash);
        if (match.length) {
          match[0].scrollIntoView();
        }
      });

      return () => clearTimeout(timeout);
    }
  }, [hash]);

  const [openedId, setOpenedId] = useState<string | null>(
    initialOpenedId || null
  );

  useEffect(() => {
    if (query) setOpenedId(null);
  }, [query]);

  return (
    <FaqWrapper>
      <Accordion>
        {sections.map((section, i) => (
          <section key={`section-${i}`}>
            <h2>{section.title}</h2>
            {section.items.map((item, j) => {
              const id = normalizedAccordionId(
                normalizeComponentTree(item.question)
              );
              return (
                <Accordion.Row
                  id={id}
                  title={item.question}
                  key={j}
                  open={openAll || openedId === id}
                  titleRef={(x: HTMLHeadingElement | null) => {
                    if (x) titles.current[i * sections.length + j] = x;
                  }}
                  onOpen={() => {
                    if (openedId !== id) {
                      setOpenedId(null);
                      onOpen(id);
                      tracking.event({
                        ...EVENTS.FAQ.OPEN_QUESTION,
                        label: item.question,
                      });
                    }
                  }}
                >
                  <Answer>{item.answer}</Answer>
                </Accordion.Row>
              );
            })}
          </section>
        ))}
      </Accordion>
    </FaqWrapper>
  );
}

export function FaqPageContent() {
  const location = useLocation();
  const { query, clear, inputProps } = useDebouncedQuery({
    queryEvent: EVENTS.FAQ.FILTER,
    queryString:
      (location.search
        ? (queryString.parse(location.search).q as string)
        : null) || "",
  });

  const [matchedSections, setMatchedSections] = useState(sections);

  useEffect(() => {
    let newSections = !query
      ? sections
      : sections
          .map((section) => {
            const items = section.items
              .map((item) => {
                return {
                  ...item,
                  questionMatch: search(item.question, query),
                  answerMatch: search(item.answer, query),
                };
              })
              .filter(
                ({ questionMatch, answerMatch }) => questionMatch || answerMatch
              )
              .map(({ ...item }) => ({
                ...item,
                question: item.questionMatch || item.question,
                answer: item.answerMatch || item.answer,
              }));

            if (!items.length) return null;

            return {
              ...section,
              items,
            };
          })
          .filter((s) => !!s);
    setMatchedSections(newSections as FaqSection[]);
  }, [query]);

  return (
    <>
      <FormGroup>
        <FilterField label="Søk på siden" inputProps={inputProps} />
      </FormGroup>
      <Faq
        query={query || undefined}
        sections={matchedSections}
        openAll={!!query}
        openedId={location.hash ? location.hash.replace(/^#/, "") : undefined}
        onOpen={(id) => {
          if (!query) {
            window.history.replaceState(
              null,
              document.title,
              document.location.hash.length
                ? document.location.href.replace(
                    document.location.hash,
                    `#${id}`
                  )
                : `${document.location.href}#${id}`
            );
          }
        }}
      />
      {query && !matchedSections.length ? (
        <NoResultsPlaceholder query={query} clear={clear} />
      ) : null}
    </>
  );
}

export default function FaqPage() {
  const title = "Vanlige spørsmål";

  useDocumentTitle(`${title} ${TITLE_POSTFIX}`);

  return (
    <StaticHeroPage title={title} returnTo={StaticRoutes.landing.path}>
      <Card>
        <AboutContent>
          <FaqPageContent />
        </AboutContent>
      </Card>
    </StaticHeroPage>
  );
}
