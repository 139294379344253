import {
  Insuree,
  ContactInfo,
  VehicleData,
  Insurance,
  Driver,
  Gender,
  AccidentDescription,
  AccidentReport,
  Coordinates,
  AccidentQuestions,
  TrailerData,
  CollisionTouch,
  CollisionTouchType,
  TextSection,
  Witness,
  VehicleKey,
  VehicleKeyOrNone,
  SupplementalInfo,
  Injuree,
  PageTwo,
  Vehicle,
  PageTwoStatus,
  InjureeContactInfo,
  VehicleReportStatus,
} from "./AccidentReport";
import { addMinutes, differenceInYears, format, parseISO } from "date-fns";

const ACCIDENT_QUESTIONS: FieldLabels<AccidentQuestions> = {
  standardFields: {
    vehicleStill: {
      type: "options",
      options: [
        {
          value: "Parked",
          label: "Parkert",
        },
        { value: "Still", label: "Sto stille i kollisjonsøyeblikket" },
      ],
      pdfLabel: "\\*Parkert/\n\\*Sto stille i kollisjonsøyeblikket",
      label:
        "Velg det alternativet som var aktuelt for deg i kollisjonsøyeblikket:",
      placeholder: "Ikke aktuelt",
    },
    vehicleStarting: {
      type: "options",
      options: [
        { value: "Starting", label: "Satt kjøretøyet i bevegelse" },
        { value: "OpenedDoor", label: "Åpnet døra" },
      ],
      pdfLabel: "\\*Satt kjøretøyet i bevegelse/\n\\*Åpnet døra",
      label:
        "Velg det alternativet som var aktuelt for deg i kollisjonsøyeblikket:",
      placeholder: "Ikke aktuelt",
    },
    wasStopping: { type: "boolean", label: "Var i ferd med å stoppe" },
    exitingParking: {
      type: "boolean",
      pdfLabel:
        "Kjørte _ut_ fra parkeringsplass,\nprivat område, gårdsvei o.l.",
      label:
        "Kjørte <em>ut</em> fra parkeringsplass, privat område, gårdsvei o.l.",
    },

    enteringParking: {
      type: "boolean",
      pdfLabel:
        "Kjørte _inn_ på parkeringsplass,\nprivat område, gårdsvei o.l.",
      label:
        "Kjørte <em>inn</em> på parkeringsplass,\nprivat område, gårdsvei o.l.",
    },
    enteringRoundabout: {
      type: "boolean",
      pdfLabel: "Kjørte _inn_ i en rundkjøring",
      label: "Kjørte <em>inn</em> i en rundkjøring",
    },
    exitingRoundabout: {
      type: "boolean",
      pdfLabel: "Kjørte _i_ en rundkjøring",
      label: "Kjørte <em>i</em> en rundkjøring",
    },
    hitFromBehind: {
      type: "boolean",
      pdfLabel: "Kjørte på bakfra i samme retning\nog samme kjørefelt",
      label: "Kjørte på bakfra i samme retning og samme kjørefelt",
    },
    drivingParallelLane: {
      type: "boolean",
      pdfLabel: "Kjørte i samme retning\ni annet kjørefelt",
      label: "Kjørte i samme retning i annet kjørefelt",
    },
    changedLane: { type: "boolean", label: "Skiftet kjørefelt" },
    wasPassing: { type: "boolean", label: "Kjørte forbi" },
    turnedRight: {
      type: "boolean",
      pdfLabel: "Svingte til _høyre_",
      label: "Svingte til <em>høyre</em>",
    },
    turnedLeft: {
      type: "boolean",
      pdfLabel: "Svingte til _venstre_",
      label: "Svingte til <em>venstre</em>",
    },
    wasBackingUp: { type: "boolean", label: "Rygget" },
    enteredWrongDirectionLane: {
      type: "boolean",
      pdfLabel:
        "Kom inn på del av veien bestemt\nfor trafikk i motsatt retning",
      label: "Kom inn på del av veien bestemt for trafikk i motsatt retning",
    },
    cameFromRightAtIntersection: {
      type: "boolean",
      label: "Kom fra høyre i et kryss",
    },

    ignoredRightOfWaySignalOrRedLight: {
      type: "boolean",
      pdfLabel: "Fulgte ikke varsel\nom vikeplikt eller rødt lys",
      label: "Fulgte ikke varsel om vikeplikt eller rødt lys",
    },
  },
  derivedFields: {},
};

ACCIDENT_QUESTIONS.derivedFields.sum = {
  label: "Antall avkryssede felt",
  dependencies: Object.keys(
    ACCIDENT_QUESTIONS.standardFields
  ) as (keyof AccidentQuestions)[],
  transform: function (args: (boolean | null)[]) {
    return args.filter((x) => x).length.toString();
  },
  type: "number",
};

export type FieldType =
  | "text"
  | "email"
  | "phone"
  | "date"
  | "number"
  | "boolean"
  | "options"
  | "optionsMultiple"
  | "postalCode";

export interface ValidationSpecification {
  isRequired?: boolean;
  pattern?: { match: string; error: string };
  maxLength?: number;
}

export interface StandardField {
  type: FieldType;
  options?: { value: string | number | boolean; label: string }[];
  pdfLabel?: string;
  label: string;
  placeholder?: string;
  description?: string;
  suffix?: string;
  validation?: ValidationSpecification;
  transform?: (value: any) => string;
}

interface JoinedFieldBase {
  type: FieldType;
  label: string;
  transform(values: any[]): string;
}

interface JoinedField extends JoinedFieldBase {
  dependencies: string[];
}

export type FieldDefinition = StandardField | JoinedField;

interface JoinedFieldGeneric<T> extends JoinedFieldBase {
  dependencies: Array<keyof T>;
}

export type StandrdFields<T> = {
  [key in keyof T]: StandardField;
};

export type DerivedFields<T> = {
  [key: string]: JoinedFieldGeneric<T>;
};

export type FieldLabels<T> = {
  standardFields: StandrdFields<T>;
  derivedFields?: DerivedFields<T>;
};

function join(fields: string[]) {
  return fields.filter((c) => c).join(", ");
}

function uppercase(value: string) {
  return value.toUpperCase();
}

function formatDate(value: string) {
  const d = parseISO(value);
  return format(addMinutes(d, d.getTimezoneOffset()), "dd.MM.yyyy");
}

const ACCIDENT_DESCRIPTION: FieldLabels<AccidentDescription> = {
  standardFields: {
    date: {
      type: "date",
      label: "Skadedato",
      validation: {
        isRequired: true,
      },
    },
    location: {
      type: "text",
      label: "Lokasjon",
      placeholder: "Kryss, gatenavn etc.",
      validation: {
        isRequired: true,
      },
    },
    coordinates: {
      type: "text",
      label: "Koordinater",
    },
    county: {
      type: "text",
      label: "Kommune",
      placeholder: "Kommune der hendelsen skjedde",
      validation: {
        isRequired: true,
      },
    },
    country: {
      type: "text",
      label: "Land",
      validation: {
        isRequired: true,
      },
    },
    postalCode: {
      type: "postalCode",
      label: "Postnummer",
      validation: {
        isRequired: true,
      },
    },
    injury: {
      type: "boolean",
      label: "Personskade",
      options: [
        { value: false, label: "NEI" },
        { value: true, label: "JA" },
      ],
      description:
        "<p>Huk av dersom uhellet har forårsaket personskade. Personskader skal meldes til politiet. Alle som er innblandet i eller kommer over en trafikkulykke er forpliktet til å stanse og hjelpe personer og dyr som er kommet til skade.</p>",
    },
    materialDamageOtherThanInvolvedVehicles: {
      type: "boolean",
      label: "Annen materiell skade enn på kjøretøy A og B",
      options: [
        { value: false, label: "NEI" },
        { value: true, label: "JA" },
      ],
      description:
        "<p>Huk av dersom uhellet har forårsaket andre materielle skader enn på kjøretøy A og B.</p>",
    },
    damageToNonVehicleObjects: {
      type: "boolean",
      label: "Skade på andre objekter enn kjøretøy",
      options: [
        { value: false, label: "NEI" },
        { value: true, label: "JA" },
      ],
      description:
        "<p>Huk av dersom uhellet har forårsaket skade på andre objekter enn kjøretøy.</p>",
    },
  },
  derivedFields: {
    datePart: {
      type: "text",
      label: "Skadedato",
      dependencies: ["date"],
      transform: ([date]) => date && formatDate(date),
    },
    timePart: {
      type: "text",
      dependencies: ["date"],
      label: "Klokken",
      transform: ([date]) => {
        if (!date) return null;
        const d = parseISO(date);
        return format(addMinutes(d, d.getTimezoneOffset()), "HH:mm");
      },
    },
    combinedLocation: {
      type: "text",
      label: "(gate/gatekryss, veinr. husnr. mest mulig eksakt)",
      dependencies: ["location", "coordinates"],
      transform: ([location, coordinates]: [string, Coordinates]) =>
        `${location || ""}${
          coordinates
            ? ` (${coordinates.latitude}, ${coordinates.longitude})`
            : ""
        }`,
    },
  },
};

const genderField: FieldDefinition = {
  type: "options",
  options: [
    { value: Gender.Male, label: "Mann" },
    { value: Gender.Female, label: "Kvinne" },
  ],
  label: "Kjønn",
  placeholder: "Ikke oppgitt",
};

const WITNESS: FieldLabels<Witness> = {
  derivedFields: {
    combinedWitnessDetails: {
      type: "text",
      dependencies: [
        "phone",
        "fullName",
        "address",
        "postalCode",
        "county",
        "passenger",
      ],
      label: "Detaljer",
      transform: function ([
        phone,
        fullName,
        address,
        postalCode,
        county,
        passenger,
      ]: [
        string | null | undefined,
        string | null | undefined,
        string | null | undefined,
        string | null | undefined,
        string | null | undefined,
        VehicleKeyOrNone | null | undefined
      ]) {
        const parts: string[] = [];

        if (fullName) parts.push(fullName);
        if (phone) parts.push(`tlf. ${phone}`);
        if (address) parts.push(address);
        if (postalCode || county)
          parts.push([postalCode, county].filter((p) => p).join(" "));

        return (
          parts.join(", ") +
          (passenger && passenger !== "None"
            ? ` (passasjer i kjøretøy ${passenger})`
            : "")
        );
      },
    },
  },
  standardFields: {
    hadWitness: {
      type: "options",
      label: "Var det vitner tilstede?",
      options: [
        { value: false, label: "Nei" },
        { value: true, label: "Ja" },
      ],
    },
    phone: {
      label: "Mobilnummer",
      type: "text",
      placeholder: "8 siffer",
    },
    fullName: {
      label: "Fullt navn",
      type: "text",
      placeholder: "Fornavn og etternavn",
    },
    address: {
      label: "Adresse",
      type: "text",
      placeholder: "Gate, vei, gatenr.",
    },
    postalCode: {
      label: "Postnr.",
      type: "text",
      placeholder: "4 siffer",
    },
    county: {
      label: "Poststed",
      type: "text",
      placeholder: "Sted",
    },
    passenger: {
      label: "Vitnet var passasjer",
      type: "options",
      options: [
        {
          value: VehicleKey.A,
          label: "Kjøretøy A",
        },
        {
          value: VehicleKey.B,
          label: "Kjøretøy B",
        },
        {
          value: "None",
          label: "Var ikke passasjer",
        },
      ],
    },
  },
};

const INSUREE: FieldLabels<Insuree> = {
  standardFields: {
    vatResponsible: {
      type: "boolean",
      pdfLabel: "Oppgave-pliktig for m.v.a",
      label: "Oppgavepliktig for mva.",
      description:
        "Gjelder dersom bilen er brukt av en virksomhet, selskap eller som firmabil.",
    },
    sameAsDriver: {
      type: "boolean",
      label: "Forsikringstakeren var fører av kjøretøyet",
      validation: { isRequired: true },
    },
    gender: genderField,
  },
};

const CONTACT_INFO: FieldLabels<ContactInfo> = {
  standardFields: {
    phone: { type: "phone", label: "Telefonnummer", placeholder: "8 siffer" },
    email: {
      type: "email",
      label: "E-postadresse",
      placeholder: "E-post",
    },
    firstName: {
      type: "text",
      label: "Fornavn, mellomnavn",
      transform: uppercase,
      placeholder: "Fornavn",
    },
    lastName: {
      type: "text",
      pdfLabel: "Etternavn (BRUK BLOKKBOKSTAVER)",
      label: "Etternavn",
      transform: uppercase,
      placeholder: "Etternavn",
    },
    birthDate: { label: "Fødselsdato", transform: formatDate, type: "date" },
    address: {
      type: "text",
      label: "Adresse",
      transform: uppercase,
      placeholder: "Gatenavn",
    },
    postalCode: {
      type: "postalCode",
      label: "Postnummer",
      placeholder: "4 siffer",
    },
    county: { type: "text", label: "Poststed", placeholder: "Sted" },
    country: { type: "text", label: "Land", placeholder: "Velg land" },
  },
  derivedFields: {
    age: {
      type: "number",
      label: "Alder",
      dependencies: ["birthDate"],
      transform: ([date]: string[]) =>
        date ? differenceInYears(new Date(), new Date(date)).toString() : null,
    },
    postal: {
      type: "text",
      dependencies: ["postalCode", "county", "country"],
      label: "Postnr./-sted, land",
      transform: join,
    },
    fullName: {
      type: "text",
      label: "Etternavn, fornavn",
      dependencies: ["firstName", "lastName"],
      transform: ([firstName, lastName]: string[]) =>
        [lastName, firstName].filter((c) => c).join(", "),
    },
  },
};

const DRIVER_CONTACT_INFO: FieldLabels<ContactInfo> = {
  standardFields: {
    ...CONTACT_INFO.standardFields,
    phone: {
      ...CONTACT_INFO.standardFields.phone,
      validation: { isRequired: true },
    },
    firstName: {
      ...CONTACT_INFO.standardFields.firstName,
      validation: { isRequired: true },
      placeholder: "Førers fornavn",
    },
    lastName: {
      ...CONTACT_INFO.standardFields.lastName,
      validation: { isRequired: true },
      placeholder: "Førers etternavn",
    },
    email: {
      ...CONTACT_INFO.standardFields.email,
      validation: { isRequired: true },
      placeholder: "Førers e-post",
    },
  },
  derivedFields: {
    ...CONTACT_INFO.derivedFields,
  },
};

const INSUREE_CONTACT_INFO: FieldLabels<ContactInfo> = {
  standardFields: {
    ...CONTACT_INFO.standardFields,
    firstName: {
      ...CONTACT_INFO.standardFields.firstName,
      placeholder: "Forsikringstakers fornavn",
    },
    lastName: {
      ...CONTACT_INFO.standardFields.lastName,
      placeholder: "Forsikringstakers etternavn",
    },
    email: {
      ...CONTACT_INFO.standardFields.email,
      placeholder: "Forsikringstakers e-post",
    },
  },
  derivedFields: {
    ...CONTACT_INFO.derivedFields,
  },
};

const VEHICLE_DATA: FieldLabels<VehicleData> = {
  standardFields: {
    licensePlateNumber: {
      type: "text",
      pdfLabel: "Kjennetegn (reg nr.)",
      label: "Registreringsnummer",
      placeholder: "Ditt registreringsnummer",
    },
    manufactureName: {
      type: "text",
      pdfLabel: "Merke",
      label: "Merke / Type",
      placeholder: "F.eks. Tesla, Volkswagen...",
    },
    modelName: {
      type: "text",
      pdfLabel: "Modellnavn",
      label: "Modell",
      placeholder: "F.eks. Model X, Golf...",
    },
    registrationCountry: { type: "text", label: "Registreringsland" },
  },
  derivedFields: {
    makeModel: {
      type: "text",
      dependencies: ["manufactureName", "modelName"],
      label: "Merke/Type",
      transform: join,
    },
  },
};

const TRAILER_DATA: FieldLabels<TrailerData> = {
  standardFields: {
    hasTrailer: {
      type: "boolean",
      options: [
        { value: false, label: "Nei" },
        { value: true, label: "Ja" },
      ],
      label: "Tilhenger",
    },
    ...VEHICLE_DATA.standardFields,
  },
  derivedFields: {
    ...VEHICLE_DATA.derivedFields,
  },
};

const INSURANCE: FieldLabels<Insurance> = {
  standardFields: {
    companyName: {
      type: "text",
      pdfLabel: "Navn",
      label: "Forsikringsselskap",
      placeholder: "Navn på selskapet kjøretøyet er forsikret gjennom",
    },
    agreementNumber: {
      type: "text",
      label: "Forsikringsavtalenr.",
      placeholder: "Ditt forsikringsavtalenr.",
    },
    greenCardNumber: {
      type: "text",
      pdfLabel: "Grønt kort nr. (skade utenfor Norden)",
      label: "Grønt kort nr.",
      description: "For skader utenfor Norden",
    },
    greenCardExpirationDate: {
      type: "date",
      pdfLabel: "Gyldig til dato",
      label: "Gyldig til",
      transform: formatDate,
    },
    comprehensiveInsurance: {
      type: "boolean",
      pdfLabel: "Kasko-forsikring",
      label: "Kaskoforsikring",
      placeholder: "Ikke oppgitt",
    },
  },
};

const DRIVER: FieldLabels<Driver> = {
  standardFields: {
    driversLicenseNumber: {
      type: "text",
      label: "Førerkortnr.",
    },
    driversLicenseClass: {
      type: "text",
      label: "Klasse",
      placeholder: "B, B 96 osv.",
    },
    issueDate: {
      type: "text",
      label: "Utstedt dato",
      transform: formatDate,
    },
    expirationDate: {
      type: "text",
      label: "Gyldig til",
      transform: formatDate,
    },
    gender: genderField,
  },
};

const COLLISION_TOUCH: FieldLabels<CollisionTouch> = {
  standardFields: {
    type: {
      type: "text",
      label: "Kjøretøystype",
      options: [
        { label: "MC", value: CollisionTouchType.MC },
        { label: "Personbil", value: CollisionTouchType.Car },
        { label: "Stor bil", value: CollisionTouchType.Trailer },
      ],
      placeholder: "Velg kjøretøystype",
    },
    x: {
      type: "number",
      label: "Posisjon",
    },
    y: {
      type: "number",
      label: "Posisjon",
    },
  },
};

const DAMAGE_DESCRIPTION: FieldLabels<TextSection> = {
  standardFields: {
    text: {
      type: "text",
      label: "Synlige skader på kjøretøyet",
      placeholder: "Beskriv kort synlige skader på kjøretøyet",
      validation: { maxLength: 400 },
    },
  },
};

const COMMENTS: FieldLabels<TextSection> = {
  standardFields: {
    text: {
      type: "text",
      label: "Bemerkninger/eventuelle forbehold ved uenighet",
      pdfLabel: "Bemerkninger / eventuelle forhold v/uenighet",
      description:
        "Dersom det er uenighet om detaljer på skademeldingen ta forbehold om dette her.",
    },
  },
};

function isVehicleField(
  x: FieldSpecification
): x is DerivedVehicleFieldSpecification | StandardVehicleFieldSpecification {
  return "vehicleField" in x && x.vehicleField;
}

function isPageTwoField(x: FieldSpecification): x is PageTwoFieldSpecification {
  return "pageTwoField" in x && x.pageTwoField;
}

export class StandardCommonFieldSpecification {
  constructor(
    public section: keyof Schema["commonSections"],
    public key: string
  ) {}
}

export class DerivedCommonFieldSpecification {
  constructor(
    public section: keyof Schema["commonSections"],
    public derivedKey: string
  ) {}
}

export class StandardVehicleFieldSpecification {
  vehicleField = true;

  constructor(
    public section: keyof VehicleFieldSectionsSchema,
    public key: string
  ) {}
}

export class DerivedVehicleFieldSpecification {
  vehicleField = true;

  constructor(
    public section: keyof VehicleFieldSectionsSchema,
    public derivedKey: string
  ) {}
}

export class StandardPageTwoFieldSpecification {
  pageTwoField = true;

  constructor(
    public section: keyof PageTwoSectionsSchema,
    public key: string
  ) {}
}

export class DerivedPageTwoFieldSpecification {
  pageTwoField = true;

  constructor(
    public section: keyof PageTwoSectionsSchema,
    public derivedKey: string
  ) {}
}

export type VehicleFieldSpecification =
  | StandardVehicleFieldSpecification
  | DerivedVehicleFieldSpecification;
export type CommonFieldSpecification =
  | StandardCommonFieldSpecification
  | DerivedCommonFieldSpecification;
export type PageTwoFieldSpecification =
  | StandardPageTwoFieldSpecification
  | DerivedPageTwoFieldSpecification;

export type FieldSpecification =
  | VehicleFieldSpecification
  | CommonFieldSpecification
  | PageTwoFieldSpecification;

export type VehicleSection = keyof VehicleFieldSectionsSchema;
export type VehicleField<TSection extends VehicleSection> =
  keyof VehicleFieldSectionsSchema[TSection]["standardFields"];

export type PageTwoSection = keyof PageTwoSectionsSchema;
export type PageTwoField<TSection extends PageTwoSection> =
  keyof PageTwoSectionsSchema[TSection]["section"]["standardFields"];

export type CommonSection = keyof CommonSectionsSchema;

export function vehicleField<TSection extends VehicleSection>(
  section: TSection,
  key: VehicleField<TSection>
): StandardVehicleFieldSpecification {
  return new StandardVehicleFieldSpecification(section, <string>key);
}

export function derivedVehicleField<
  TSection extends keyof VehicleFieldSectionsSchema
>(
  section: TSection,
  key: keyof VehicleFieldSectionsSchema[TSection]["derivedFields"]
): DerivedVehicleFieldSpecification {
  return new DerivedVehicleFieldSpecification(section, <string>key);
}

export function pageTwoField<TSection extends PageTwoSection>(
  section: TSection,
  key: PageTwoField<TSection>
) {
  return new StandardPageTwoFieldSpecification(section, <string>key);
}

export function derivedPageTwoField<
  TSection extends keyof PageTwoSectionsSchema
>(
  section: TSection,
  key: keyof PageTwoSectionsSchema[TSection]["section"]["derivedFields"]
) {
  return new DerivedPageTwoFieldSpecification(section, <string>key);
}

export function commonField<TSection extends keyof Schema["commonSections"]>(
  section: TSection,
  key: keyof Schema["commonSections"][TSection]["standardFields"]
): StandardCommonFieldSpecification {
  return new StandardCommonFieldSpecification(section, <string>key);
}

export function derivedCommonField<
  TSection extends keyof Schema["commonSections"]
>(
  section: TSection,
  key: keyof Schema["commonSections"][TSection]["derivedFields"]
): DerivedCommonFieldSpecification {
  return new DerivedCommonFieldSpecification(section, <string>key);
}

const SUPPLEMENTAL_INFO: FieldLabels<SupplementalInfo> = {
  standardFields: {
    startVelocity: {
      label: "Da situasjonen oppsto",
      type: "number",
      suffix: "Km/t",
    },
    collisionVelocity: {
      label: "I kollisjonsøyeblikket",
      pdfLabel: "I kollisjons-øyeblikket",
      type: "number",
      suffix: "Km/t",
    },
    speedLimit: {
      label: "Fartsgrense på stedet",
      type: "number",
      suffix: "Km/t",
    },
    surface: {
      label: "Veidekke",
      type: "optionsMultiple",
      options: [
        {
          label: "Fast dekke",
          value: "Solid",
        },
        {
          label: "Grus",
          value: "Gravel",
        },
      ],
    },
    condition: {
      label: "Føre",
      type: "optionsMultiple",
      options: [
        {
          label: "Tørt",
          value: "Dry",
        },
        {
          label: "Vått",
          value: "Wet",
        },
        {
          label: "Snø/is",
          value: "SnowOrIce",
        },
      ],
    },
    weather: {
      label: "Værforhold",
      type: "optionsMultiple",
      options: [
        {
          label: "Opphold",
          value: "DryWeather",
        },
        {
          label: "Regn",
          value: "Rain",
        },
        {
          label: "Snø",
          value: "Snow",
        },
        {
          label: "Tåke",
          value: "Fog",
        },
      ],
    },
    equipmentUsed: {
      label: "Utstyr som var i bruk",
      type: "optionsMultiple",
      options: [
        {
          label: "Kjettinger",
          value: "Chains",
        },
        {
          label: "Vinterdekk uten pigger",
          value: "StudlessWinterTires",
        },
        {
          label: "Piggdekk",
          value: "StuddetTires",
        },
        {
          label: "Sommerdekk",
          value: "SummerTires",
        },
      ],
    },
    temperature: {
      label: "Temperatur (ca.)",
      type: "number",
      suffix: "°C",
    },
    lightConditions: {
      label: "Lysforhold",
      type: "optionsMultiple",
      options: [
        {
          label: "Dagslys",
          value: "Daylight",
        },
        {
          label: "Skumring",
          value: "Dawn",
        },
        {
          label: "Mørkt",
          value: "Dark",
        },
        {
          label: "Gatelys tent",
          value: "StreetLights",
        },
      ],
    },
    lightsUsed: {
      label: "Lys som var i bruk",
      type: "optionsMultiple",
      options: [
        {
          label: "Fjernlys",
          value: "FarLights",
        },
        {
          label: "Nærlys",
          value: "NearLights",
        },
        {
          label: "Parklys",
          value: "ParkingLights",
        },
        {
          label: "Ekstralys",
          value: "ExtraLights",
        },
        {
          label: "Brukte ikke lys",
          value: "None",
        },
      ],
    },
    signal: {
      label: "Signal gitt med",
      type: "optionsMultiple",
      options: [
        {
          label: "Blinklys",
          value: "TurningLights",
        },
        {
          label: "Horn",
          value: "Horn",
        },
        {
          label: "Lys",
          value: "Lights",
        },
      ],
    },
    responsible: {
      label: "Ansvarlig",
      type: "optionsMultiple",
      description: "Hvem var etter din mening ansvaring for skadene?",
      options: [
        {
          label: "Fører A",
          value: VehicleKey.A,
        },
        {
          label: "Fører B",
          value: VehicleKey.B,
        },
        {
          label: "Annen",
          value: "Other",
        },
        {
          label: "Vet ikke",
          value: "Unknown",
        },
      ],
    },
    businessVehicle: {
      label: "Bilen ble benyttet i næring",
      type: "boolean",
      options: [
        {
          value: true,
          label: "Ja",
        },
        {
          value: false,
          label: "Nei",
        },
      ],
      placeholder: "Ikke oppgitt",
    },
    driverIntoxicated: {
      label: "Hadde føreren inntatt akohol/rusmidler/trafikkfarlige midler?",
      type: "boolean",
      options: [
        {
          value: true,
          label: "Ja",
        },
        {
          value: false,
          label: "Nei",
        },
      ],
      placeholder: "Ikke oppgitt",
    },
    substances: {
      label: "Hvis ja, kryss av for:",
      type: "optionsMultiple",
      options: [
        {
          label: "Alkohol",
          value: "Alcohol",
        },
        {
          label: "Andre rusmidler",
          value: "OtherSubstances",
        },
        {
          label: "Legemidler",
          value: "Drugs",
        },
      ],
    },
    bloodTested: {
      label: "Utåndings-/blodprøve tatt",
      type: "boolean",
      options: [
        {
          value: true,
          label: "Ja",
        },
        {
          value: false,
          label: "Nei",
        },
      ],
      placeholder: "Ikke oppgitt",
    },
    mileage: {
      label: "Kilometerstand",
      type: "number",
      suffix: "Km",
    },
    roadAssistanceCalled: {
      label: "Er bilredningsfirma tilkalt?",
      type: "boolean",
      options: [
        {
          value: true,
          label: "Ja",
        },
        {
          value: false,
          label: "Nei",
        },
      ],
      placeholder: "Ikke oppgitt",
    },
    roadAssistanceCompany: {
      label: "Hvis ja, hvilket firma?",
      type: "text",
      placeholder: "F.eks: NAF, Falck, Viking",
    },
    materialDamageOtherThanInvolvedVehicles: {
      label:
        "Annen <em>materiell</em> skade enn på kjøretøy A og B (skadet gjenstand/eiendom)",
      pdfLabel: "Annen materiell skade enn på kjøretøy A og B",
      type: "text",
      placeholder: "Skriv her",
    },
    previousDamage: {
      label: "Tidligere skader",
      type: "boolean",
      options: [
        {
          value: true,
          label: "Ja",
        },
        {
          value: false,
          label: "Nei",
        },
      ],
      placeholder: "Ikke oppgitt",
    },
    previousDamageDescription: {
      label: "Hvis ja, gi en kort beskrivelse",
      type: "text",
      placeholder: "Skriv her",
    },
    policeCase: {
      label: "Behandles saken hos politiet?",
      type: "boolean",
      options: [
        {
          value: true,
          label: "Ja",
        },
        {
          value: false,
          label: "Nei",
        },
      ],
      placeholder: "Ikke oppgitt",
    },
    policeDistrict: {
      label: "Hvis ja, hvilket politidistrikt?",
      type: "text",
      placeholder: "Politidistrikt",
    },
    // PDF only
    injuriesInOtherVehicle: {
      label: "Skadet person i motpartens kjøretøy",
      type: "boolean",
      options: [
        {
          value: true,
          label: "Ja",
        },
        {
          value: false,
          label: "Nei",
        },
      ],
    },
    numInjuredPersonsInOtherVehicle: {
      label: "Hvis ja, hvor mange?",
      type: "number",
    },
    confirmation: {
      label:
        "Ved innsendelse av skademeldingen bekrefter jeg at opplysningene gitt er korrekte",
      type: "boolean",
    },
  },
};

const PAGE_TWO_ACCIDENT_DESCRIPTION: FieldLabels<TextSection> = {
  standardFields: {
    text: {
      type: "text",
      label:
        "Beskriv så nøye som mulig hva som har skjedd og hva du selv gjorde før og etter hendelsen.",
      placeholder: "Skriv her",
    },
  },
};

const INJUREE: FieldLabels<Injuree> = {
  standardFields: {
    role: {
      type: "optionsMultiple",
      label: "",
      options: [
        {
          label: "Fører",
          value: "Driver",
        },
        {
          label: "Passasjer",
          value: "Pasenger",
        },
        {
          label: "Fotgjenger",
          value: "Pedestrian",
        },
        {
          label: "Syklist",
          value: "Cyclist",
        },
      ],
    },
    securityEquipmentUsed: {
      type: "optionsMultiple",
      label: "Sikring",
      options: [
        {
          label: "Bilbelte",
          value: "Belt",
        },
        {
          label: "Kollisjonspute",
          value: "CrashPad",
        },
        {
          label: "Barnesikring",
          value: "BoosterSeat",
        },
        {
          label: "Hjelm",
          value: "Helmet",
        },
        {
          label: "Ingen",
          value: "None",
        },
      ],
    },
    treatment: {
      type: "optionsMultiple",
      label: "Behandling",
      options: [
        {
          label: "Omkommet",
          value: "Deceased",
        },
        {
          label: "Innlagt på sykehus",
          value: "Hospitalized",
        },
        {
          label: "Legebehandlet",
          value: "TreatedByMedicalProfessional",
        },
      ],
    },
    hospital: {
      type: "text",
      label: "Behandlet av lege/sykehus",
      placeholder: "Navn på lege/sykehus",
    },
  },
};

const PAGE_TWO_STATUS: FieldLabels<PageTwoStatus> = {
  standardFields: {
    isComplete: {
      type: "boolean",
      label: "Side to ferdig",
    },
  },
};

const VEHICLE_REPORT_STATUS: FieldLabels<VehicleReportStatus> = {
  standardFields: {
    isComplete: {
      type: "boolean",
      label: "Jeg er ferdig med min del",
    },
    updated: {
      type: "date",
      label: "Sist endret",
    },
  },
};

const DAMAGED_PROPERTY_OWNER_CONTACT_INFO: FieldLabels<ContactInfo> = {
  standardFields: {
    ...CONTACT_INFO.standardFields,
  },
  derivedFields: {
    nameAndAddress: {
      label: "Eierens navn og adresse",
      dependencies: [
        "firstName",
        "lastName",
        "address",
        "postalCode",
        "county",
        "country",
      ],
      type: "text",
      transform: ([firstName, lastName, ...parts]: string[]) => {
        const name = [firstName, lastName].filter((x) => x).join(" ");
        const address = parts.filter((p) => p).join(", ");

        return [name, address].filter((p) => p).join(", ");
      },
    },
  },
};

const INJUREE_CONTACT_INFO: FieldLabels<InjureeContactInfo> = {
  standardFields: {
    ...CONTACT_INFO.standardFields,
    gender: genderField,
  },
  derivedFields: {
    ...CONTACT_INFO.derivedFields,
  },
};

export enum EntityType {
  Single = "Single",
  List = "List",
}

interface GetVehicleValue {
  vehicle: Vehicle;
}
interface GetPageTwoValue {
  pageTwo: PageTwo;
}

export type GetValueRequest = GetVehicleValue | GetPageTwoValue;

export class Schema {
  vehicleFieldSections = {
    insuree: INSUREE,
    insureeContactInfo: INSUREE_CONTACT_INFO,
    driverContactInfo: DRIVER_CONTACT_INFO,
    vehicleData: VEHICLE_DATA,
    trailerData: TRAILER_DATA,
    insurance: INSURANCE,
    driver: DRIVER,
    accidentQuestions: ACCIDENT_QUESTIONS,
    collisionTouch: COLLISION_TOUCH,
    damageDescription: DAMAGE_DESCRIPTION,
    comments: COMMENTS,
    witness: WITNESS,
    status: VEHICLE_REPORT_STATUS,
  };

  commonSections = {
    accidentDescription: ACCIDENT_DESCRIPTION,
  };

  pageTwoSections = {
    supplementalInfo: { type: EntityType.Single, section: SUPPLEMENTAL_INFO },
    damagedPropertyOwner: {
      type: EntityType.Single,
      section: DAMAGED_PROPERTY_OWNER_CONTACT_INFO,
    },
    accidentDescription: {
      type: EntityType.Single,
      section: PAGE_TWO_ACCIDENT_DESCRIPTION,
    },
    injureeContactInfo: {
      type: EntityType.List,
      section: INJUREE_CONTACT_INFO,
    },
    injuree: { type: EntityType.List, section: INJUREE },
    status: { type: EntityType.Single, section: PAGE_TWO_STATUS },
  };

  private getSection(field: FieldSpecification) {
    if (isVehicleField(field)) {
      const sectionDefintions = this.vehicleFieldSections[field.section];

      if ("key" in field) {
        const section = sectionDefintions.standardFields;

        return {
          section,
          sectionName: field.section,
          name: field.key,
          type: EntityType.Single,
        };
      }

      const section = sectionDefintions.derivedFields;

      return {
        section,
        sectionName: field.section,
        name: field.derivedKey,
        type: EntityType.Single,
      };
    }

    if (isPageTwoField(field)) {
      const sectionDefintions = this.pageTwoSections[field.section];

      if ("key" in field) {
        const section = sectionDefintions.section.standardFields;

        return {
          section,
          sectionName: field.section,
          name: field.key,
          type: sectionDefintions.type,
        };
      }

      const section = sectionDefintions.section.derivedFields;

      return {
        section,
        sectionName: field.section,
        name: field.derivedKey,
        type: sectionDefintions.type,
      };
    }

    const sectionDefintions = this.commonSections[field.section];

    if ("key" in field) {
      const section = sectionDefintions.standardFields;

      return {
        section,
        sectionName: field.section,
        name: field.key,
        type: EntityType.Single,
      };
    }

    const section = sectionDefintions.derivedFields;

    return {
      section,
      sectionName: field.section,
      name: field.derivedKey,
      type: EntityType.Single,
    };
  }

  getDefinition(field: FieldSpecification): {
    section: string;
    name: string;
    definition: FieldDefinition;
    type: EntityType;
  } {
    const { section, sectionName, name, type } = this.getSection(field);

    return {
      section: sectionName,
      name,
      definition: (<any>section)[name],
      type,
    };
  }

  getDisplayValue(
    field: FieldSpecification,
    report: AccidentReport,
    request?: GetValueRequest
  ) {
    const { name: key, definition, type } = this.getDefinition(field);

    let sections: any[] = [];

    if (isVehicleField(field)) {
      if (!(request && "vehicle" in request)) {
        throw new Error("Vehicle is required to get value for vehicle fields");
      }
      const vehicle = request.vehicle;
      sections.push(vehicle[field.section]);
    } else if (isPageTwoField(field)) {
      if (!(request && "pageTwo" in request)) {
        throw new Error("PageTwo is required to get value for page two fields");
      }
      const pageTwo = request.pageTwo;
      const sectionElement = pageTwo[field.section];
      if (sectionElement && "length" in sectionElement) {
        sections = sectionElement;
      } else {
        sections.push(sectionElement);
      }
    } else {
      sections = [report[field.section]];
    }

    sections = sections.filter((x) => x);

    const results = sections.map((section) => {
      if ("dependencies" in definition) {
        const keys = definition.dependencies;

        return definition.transform(keys.map((key) => (<any>section)[key]));
      }

      type RawValue = string | boolean | number | string[];

      const value: RawValue | null | undefined = (<any>section)[key];

      if (definition.transform && value) return definition.transform(value);
      if (value === null || value === undefined) return null;

      return value;
    });

    return type === EntityType.Single
      ? { type, result: results[0] }
      : { type, result: results };
  }
}

export type VehicleFieldSectionsSchema = Schema["vehicleFieldSections"];
export type PageTwoSectionsSchema = Schema["pageTwoSections"];
export type CommonSectionsSchema = Schema["commonSections"];

export function createSchema(): Schema {
  return new Schema();
}
