import React, {
  useState,
  useRef,
  useEffect,
  HTMLAttributes,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import { TrackEventProps } from "@teamscheme/tracking";
import styled from "styled-components";
import { onDesktop } from "@teamscheme/theme";
import { getScrollbarWidth } from "@teamscheme/disable-body-scroll";
import { useTracking } from "../Tracking";

interface Transform {
  translateX: number;
  translateY: number;
  scaleX: number;
  scaleY: number;
}

function transformString({
  translateX,
  translateY,
  scaleX,
  scaleY,
}: Transform) {
  return `translateX(${translateX}px) translateY(${translateY}px) scaleX(${scaleX}) scaleY(${scaleY})`;
}

interface ZoomableScrollImageProps
  extends Omit<HTMLAttributes<HTMLImageElement>, "onClick"> {
  alt?: string;
  src: string;
  isZoomed: boolean;
  setIsZoomed: Dispatch<SetStateAction<boolean>>;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onOpenEvent?: TrackEventProps;
}

const ZoomableScrollImageElement = styled.img`
  cursor: zoom-in;
`;

interface ZoomableScrollImageContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  magnified: boolean;
}

const ZoomableScrollImageContainer = styled(
  ({ magnified, ...props }: ZoomableScrollImageContainerProps) => (
    <div {...props} />
  )
)`
  cursor: zoom-out;
  display: none;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;

  ${({ magnified }) =>
    magnified &&
    `
    display: flex;
  `}
`;

const ZoomableScrollImageCanvas = styled.div`
  overflow-y: auto;
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${onDesktop`
    padding: 4px;
  `}
`;

const ZoomableScrollImageMagnified = styled.img`
  display: block;
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: auto;
`;

export default function ZoomableScrollImage({
  onClick,
  alt,
  src,
  onLoad,
  onOpenEvent,
  isZoomed,
  setIsZoomed,
  ...props
}: ZoomableScrollImageProps) {
  const [isMagnified, setIsMagnified] = useState<boolean>(false);
  const [transform, setTransform] = useState<string | null>(null);
  const [savedTransform, saveTransform] = useState<Transform | null>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const imgEl = useRef<HTMLImageElement | null>(null);
  const canvasEl = useRef<HTMLDivElement | null>(null);

  const applyZoomAnimation = useCallback(() => {
    if (!imgEl.current) return;

    const PADDING = window.innerWidth <= 720 ? 4 : 12;

    const original = imgEl.current.getBoundingClientRect();

    const scrollBarWidth = getScrollbarWidth();

    const MAX_ZOOMED_WIDTH = 1200;

    const canvasHeight = window.innerHeight - PADDING * 2;
    const canvasWidth = document.body.clientWidth - PADDING * 2;

    const hasScrollBar = document.body.clientHeight > window.innerHeight;

    const canvas = {
      left: PADDING,
      top: PADDING,
      width: canvasWidth,
      height: canvasHeight,
      right: PADDING + canvasWidth,
      bottom: PADDING + canvasHeight,
    };

    const targetWidth = Math.min(MAX_ZOOMED_WIDTH, canvasWidth);
    const targetHeight = targetWidth * (original.height / original.width);
    const canvasCenterX = canvas.left + canvasWidth / 2;
    const canvasCenterY = canvas.top + canvasHeight / 2;

    const targetTop = Math.max(canvas.top, canvasCenterY - targetHeight / 2);

    const target = {
      left: canvasCenterX - targetWidth / 2,
      top: targetTop,
      right: canvasCenterX + targetWidth / 2,
      bottom: targetTop + targetHeight,
    };

    const originalCenterX = original.left + original.width / 2;
    const originalCenterY = original.top + original.height / 2;
    const targetCenterX = target.left + targetWidth / 2;
    const targetCenterY = target.top + targetHeight / 2;

    const transformObject = {
      scaleX: original.width / targetWidth,
      scaleY: original.height / targetHeight,
      translateX: originalCenterX - targetCenterX,
      translateY: originalCenterY - targetCenterY + scrollTop,
    };

    setTransform(transformString(transformObject));
    saveTransform(transformObject);
    setIsMagnified(true);

    document.body.style.overflow = "hidden";
    document.body.style.marginRight = hasScrollBar
      ? `${scrollBarWidth}px`
      : "0";
  }, [scrollTop]);

  const removeZoomAnimation = useCallback(() => {
    if (!canvasEl.current) return;
    if (savedTransform) {
      setTransform(
        transformString({
          ...savedTransform,
          translateY:
            savedTransform?.translateY - scrollTop + canvasEl.current.scrollTop,
        })
      );
    }
    setScrollTop(canvasEl.current.scrollTop);
    setTimeout(() => {
      setIsMagnified(false);
      document.body.style.overflow = "";
      document.body.style.marginRight = "0";
    }, 150);
  }, [savedTransform, scrollTop]);

  useEffect(() => {
    if (isZoomed) {
      applyZoomAnimation();

      setTimeout(() => {
        setTransform(null);
      });
    }
  }, [isZoomed, applyZoomAnimation]);

  useEffect(() => {
    if (!isZoomed) {
      removeZoomAnimation();
    }
  }, [isZoomed, removeZoomAnimation]);

  useEffect(() => {
    if (isMagnified) {
      const escapeClose = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          setIsZoomed(false);
        }
      };

      window.addEventListener("keydown", escapeClose);
      return () => window.removeEventListener("keydown", escapeClose);
    }
  }, [isMagnified, setIsZoomed]);

  const tracking = useTracking();

  useEffect(() => {
    if (onOpenEvent && isMagnified) tracking.event(onOpenEvent);
  }, [tracking, onOpenEvent, isMagnified]);

  return (
    <>
      <ZoomableScrollImageElement
        data-hj-suppress
        ref={imgEl}
        onLoad={onLoad}
        onClick={function (e) {
          if (onClick) {
            onClick(e);
          }
        }}
        alt={alt || ""}
        src={src}
        {...props}
      />
      {ReactDOM.createPortal(
        <ZoomableScrollImageContainer
          magnified={isMagnified || false}
          onClick={() => setIsZoomed(false)}
        >
          <ZoomableScrollImageCanvas ref={canvasEl}>
            <ZoomableScrollImageMagnified
              data-hj-suppress
              alt=""
              src={src}
              style={{
                transform: transform || undefined,
                transition: "transform .15s ease",
                transformOrigin: "center",
              }}
            />
          </ZoomableScrollImageCanvas>
        </ZoomableScrollImageContainer>,
        document.body
      )}
    </>
  );
}
