import {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  ReactNode,
  createContext,
} from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import LayoutContext from "./LayoutContext";
import styled from "styled-components";

interface PanelRegistration {
  id: number;
  remove: () => void;
  update: (render: () => ReactNode) => void;
}

export interface IBottomPanelContext {
  register: (node: ReactNode) => PanelRegistration;
}

const BottomPanelContext = createContext<IBottomPanelContext | null>(null);

const BottomPanelContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
`;

const BottomPanelRow = styled.div`
  padding: 0.5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomPanelView = ({ panels }: { panels: Panel[] }) => {
  const [display, setDisplay] = useState<string | null>(null);
  const viewRef = useRef<HTMLDivElement | null>(null);
  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) throw new Error("No LayoutContext found!");
  const { setPaddingBottom } = layoutContext;
  const visible = panels.length > 0;

  function hide() {
    setDisplay("none");
  }

  function show() {
    setDisplay(null);
  }

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(show, 750);

      return () => clearTimeout(timeout);
    } else {
      hide();
    }
  }, [visible]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!viewRef.current) return;

      const { height } = viewRef.current.getBoundingClientRect();

      setPaddingBottom(height);
    });

    return () => clearTimeout(timeout);
  }, [panels, display, setPaddingBottom]);

  return ReactDOM.createPortal(
    <BottomPanelContainer
      style={{ display: display || undefined }}
      ref={viewRef}
    >
      {panels.map((p: Panel) => (
        <BottomPanelRow key={p.id}>{p.render()}</BottomPanelRow>
      ))}
    </BottomPanelContainer>,
    document.body
  );
};

BottomPanelView.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any,
};

interface Panel {
  id: number;
  render: () => ReactNode;
}

export function BottomPanelProvider({ children }: { children: ReactNode }) {
  const [panels, setPanels] = useState<Panel[]>([]);

  const idRef = useRef(0);

  const register = useCallback(function register(children: ReactNode) {
    const id = ++idRef.current;
    const panel = { id, render: () => children };

    setPanels((p) => [...p, panel]);

    return {
      id,
      remove: () => setPanels((p) => p.filter((x) => x.id !== id)),
      update: (render: () => ReactNode) =>
        setPanels((p) => [
          ...p.filter((x) => x.id !== id),
          { ...panel, render },
        ]),
    };
  }, []);

  return (
    <BottomPanelContext.Provider value={{ register }}>
      {children}
      <BottomPanelView panels={panels} />
    </BottomPanelContext.Provider>
  );
}

export const BottomPanel = ({ children }: { children: ReactNode }) => {
  const panel = useRef<PanelRegistration | null>(null);
  const bottomPanelContext = useContext(BottomPanelContext);
  if (!bottomPanelContext) throw new Error("No BottomPanelContext found!");
  const { register } = bottomPanelContext;

  useEffect(() => {
    if (children) {
      panel.current = register(children);

      return () => panel.current?.remove();
    }
  }, [register, children]);

  return null;
};

export default BottomPanel;
