export const PATH = "/stengt/split";

export function getSplitReportLocation(token: string) {
  const search = new URLSearchParams();
  search.set("token", token);
  return {
    search: search.toString(),
    pathname: PATH,
  };
}
