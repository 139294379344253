import styled from "styled-components";
import { LoadingTextProps } from "@teamscheme/loading-text";
import { NoPreviewFlowHeader } from "../layout/header/FlowHeader";
import StaticPage from "../static-pages/StaticPage";
import LoadingBox from "../LoadingBox";

const StyledLoadingBox = styled(LoadingBox)`
  padding: ${({ theme }) => theme.spacing.space16};
`;

export function StaticLoadingScreen(props: LoadingTextProps) {
  return (
    <StaticPage>
      <StyledLoadingBox {...props} />
    </StaticPage>
  );
}

export function LoadingFlowScreen(props: LoadingTextProps) {
  return (
    <>
      <NoPreviewFlowHeader />
      <StyledLoadingBox {...props} />
    </>
  );
}
