import {
  DateString,
  AccidentDescription,
  Vehicle,
  VehicleKey,
  PageTwo,
  MpsRequest,
  AttachmentsInfo,
} from "./AccidentReport";
import { EncodedSketch } from "./Sketch";

export enum SigningMethod {
  Handwritten = "Handwritten",
  BankID = "BankID",
}

export enum SignatureStatus {
  None = "None",
  Signed = "Signed",
  Declined = "Declined",
}

export interface SignatureInfoViewModel {
  created: DateString;
  hash: string;
  initiator: VehicleKey;
  signingMethod: SigningMethod;
  signatureStatusA: SignatureStatus;
  signatureStatusB: SignatureStatus;
  signatureStatus: SignatureStatus;
  signedDocumentReady: boolean;
  completed?: DateString;
}

export interface AccidentReportViewModelBase {
  id: string;
  documentHash: string;
  created: DateString;
  accidentDescription: AccidentDescription;
  accidentDrawing?: EncodedSketch;
  vehicleA: Vehicle;
  vehicleB: Vehicle;
  signature?: SignatureInfoViewModel;
  customerAutoFilledByPhone?: string;
  hasOtherParty: boolean;
  mpsRequest?: MpsRequest;
  mpsRequestAttachments?: AttachmentsInfo;
  invitation?: InvitationInfoViewModel;
}

export interface InvitationInfoViewModel {
  recipient: VehicleKey;
  recipientPhone: string;
  sender: VehicleKey;
  senderPhone: string;
  sent: DateString;
}

export interface PageTwoViewModel extends Omit<PageTwo, "document"> {
  documentHash: string;
}

export type AccidentReportViewModelA = AccidentReportViewModelBase & {
  tokenA: string;
  pageTwoA: PageTwoViewModel;
};

export type AccidentReportViewModelB = AccidentReportViewModelBase & {
  tokenB: string;
  pageTwoB: PageTwoViewModel;
};

export type AccidentReportViewModel =
  | AccidentReportViewModelA
  | AccidentReportViewModelB;
