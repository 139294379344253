import React, { useEffect, useContext } from "react";
import {
  AccidentReportViewModel,
  VehicleKey,
} from "@accident-statement/schema";
import { configureScope } from "@sentry/browser";
import {
  TrackingContext as TeamschemeTrackingContext,
  TrackEventProps,
} from "@teamscheme/tracking";

export function useSentryTag(
  tagName: string,
  tagValue: string | boolean | null | undefined
) {
  useEffect(() => {
    if (tagValue) {
      configureScope((scope) => scope.setTag(tagName, tagValue));
    }
  }, [tagName, tagValue]);
}

declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

type TrackingValue = string | number | boolean;

export function useTrackContext(
  attribute: string,
  value: TrackingValue | null | undefined
) {
  const tracking = useTracking();
  useEffect(() => {
    if (value !== null && value !== undefined) {
      tracking.context({ [attribute]: value });
    }
  }, [attribute, tracking, value]);
}

interface IAppTrackingContext {
  event(props: TrackEventProps): void;
  pageView({ title, path }: { title: string; path: string }): void;
  reportLoad(report: AccidentReportViewModel, party: VehicleKey): void;
  experiment(experimentName: string): void;
  appLoad(): void;
  context(contextObject: { [attribute: string]: TrackingValue }): void;
}
const AppTrackingContext = React.createContext<IAppTrackingContext | null>(
  null
);

export function AppTrackingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  function trackEvent({
    category,
    action,
    label,
    value,
    ...params
  }: TrackEventProps) {
    window.dataLayer.push({
      event: "event",
      category,
      action,
      label,
      value,
      ...params,
    });

    console.log("event", {
      category,
      action,
      label,
      value,
    });
  }

  function trackPageView({ title, path }: { title: string; path: string }) {
    window.dataLayer.push({
      event: "VirtualPageView",
      virtualPageURL: path,
      virtualPageTitle: title,
    });

    console.log("pageview", path, title);
  }

  function trackReportLoad(report: AccidentReportViewModel, party: VehicleKey) {
    const params = {
      id: report.id,
      party,
    };

    window.dataLayer.push({
      event: "report",
      ...params,
    });

    console.log("report", params);
  }

  function trackExperiment(experimentName: string) {
    window.dataLayer.push({
      event: "experiment",
      experimentName,
    });

    console.log("experiment", experimentName);
  }

  function trackAppLoad() {
    window.dataLayer.push({
      event: "load",
      appVersion: process.env.REACT_APP_VERSION,
    });
  }

  function addTrackingContext(contextObject: {
    [attribute: string]: TrackingValue;
  }) {
    for (const [attribute, value] of Object.entries(contextObject)) {
      window.dataLayer.push({ [attribute]: value });
    }
  }

  const trackingContext = { trackEvent };
  const appTrackingContext = {
    event: trackEvent,
    pageView: trackPageView,
    appLoad: trackAppLoad,
    reportLoad: trackReportLoad,
    experiment: trackExperiment,
    context: addTrackingContext,
  };

  return (
    <TeamschemeTrackingContext.Provider value={trackingContext}>
      <AppTrackingContext.Provider value={appTrackingContext}>
        {children}
      </AppTrackingContext.Provider>
    </TeamschemeTrackingContext.Provider>
  );
}

export function useTracking() {
  const context = useContext(AppTrackingContext);
  if (!context) {
    throw new Error("No AppTrackingContext found!");
  }
  return context;
}

export function externalLinkEvent(href: string) {
  return {
    ...EVENTS.COMMON.OPEN_EXTERNAL_LINK,
    label: href,
  };
}

const CATEGORIES = {
  COMMON: "Common",
  SIGNING: "Signing",
  FOOTER: "Footer",
  LANDING_PAGE: "Landing Page",
  CONFIRMATION_PAGE: "Confirmation",
  FAQ_PAGE: "FAQ Page",
  PRIVACY_PAGE: "Privacy Page",
  ATTACHMENTS_PAGE: "Attachments Page",
  REPORT_UPDATES_MODAL: "Report Updates Modal",
  PAGE_TWO: "Page Two",
  BECOME_MEMBER_POPUP: "Become Member Popup",
  MPS: "MPS",
  MPS_ATTACHMENTS: "MPS attachments",
  HEADER: "Header",
  VERIFICATION: "Verification",
};

export const EVENTS = {
  COMMON: {
    OPEN_EXTERNAL_LINK: {
      category: CATEGORIES.COMMON,
      action: "Open External Link",
    },
    PLAY_VIDEO: {
      category: CATEGORIES.COMMON,
      action: "Play Video",
    },
    AUTOFILL_CONTACT_INFO: {
      category: CATEGORIES.COMMON,
      action: "Autofill Contact Info",
    },
    AUTOFILL_VEHICLE_DATA: {
      category: CATEGORIES.COMMON,
      action: "Autofill Vehicle Data",
    },
  },
  SIGNING: {
    CANCEL: {
      category: CATEGORIES.SIGNING,
      action: "Cancel",
    },
    SELECT_SIGNING_METHOD: {
      category: CATEGORIES.SIGNING,
      action: "Select Signing Method",
    },
    OPEN: {
      category: CATEGORIES.SIGNING,
      action: "Open",
    },
    COMPLETED: {
      category: CATEGORIES.SIGNING,
      action: "Completed",
    },
  },
  FOOTER: {
    EMAIL_CUSTOMER_SERVICE: {
      category: CATEGORIES.FOOTER,
      action: "Email Customer Service",
    },
  },
  LANDING_PAGE: {
    CREATE_REPORT: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Create Report",
    },
    CANCEL_CREATE_REPORT: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Create Report",
    },
    GO_TO_CREATE_REPORT: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Access Create Report Page",
    },
    OPEN_SAVED_REPORT: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Open Saved Report",
    },
    SEE_MORE: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Expand List of Saved Reports",
    },
    OPEN_QUESTION: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Open Question",
    },
  },
  CONFIRMATION_PAGE: {
    DOWNLOAD_ZIP: {
      category: CATEGORIES.CONFIRMATION_PAGE,
      action: "Download ZIP",
    },
  },
  FAQ: {
    FILTER: {
      category: CATEGORIES.FAQ_PAGE,
      action: "Filter",
    },
    OPEN_QUESTION: {
      category: CATEGORIES.FAQ_PAGE,
      action: "Open Question",
    },
  },
  PRIVACY_PAGE: {
    EMAIL_DELETE_DATA: {
      category: CATEGORIES.PRIVACY_PAGE,
      action: "Request deletion of data",
    },
  },
  ATTACHMENTS: {
    REMOVE: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Remove",
    },
    DROP: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Drag-and-Drop to Upload",
    },
    UPLOAD: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Click to Upload",
    },
    DOWNLOAD_ZIP: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Download Zip",
    },
  },
  REPORT_UPDATES_MODAL: {
    OPEN: {
      category: CATEGORIES.REPORT_UPDATES_MODAL,
      action: "Open",
    },
    CLOSE: {
      category: CATEGORIES.REPORT_UPDATES_MODAL,
      action: "Ok",
    },
    DISMISS: {
      category: CATEGORIES.REPORT_UPDATES_MODAL,
      action: "Dismiss",
    },
  },
  PAGE_TWO: {
    SAVE: {
      category: CATEGORIES.PAGE_TWO,
      action: "Save",
    },
  },
  BECOME_MEMBER_POPUP: {
    OPEN: {
      category: CATEGORIES.BECOME_MEMBER_POPUP,
      action: "Open",
    },
    OPEN_AUTOMATICALLY: {
      category: CATEGORIES.BECOME_MEMBER_POPUP,
      action: "Open Automatically",
    },
    DISMISS: {
      category: CATEGORIES.BECOME_MEMBER_POPUP,
      action: "Dismiss",
    },
    DECLINE: {
      category: CATEGORIES.BECOME_MEMBER_POPUP,
      action: "Decline",
    },
    BECOME_MEMBER: {
      category: CATEGORIES.BECOME_MEMBER_POPUP,
      action: "Become Member",
    },
    LOG_IN: {
      category: CATEGORIES.BECOME_MEMBER_POPUP,
      action: "Log In",
    },
  },
  MPS_ATTACHMENTS: {
    REMOVE: {
      category: CATEGORIES.MPS_ATTACHMENTS,
      action: "Remove",
    },
    DROP: {
      category: CATEGORIES.MPS_ATTACHMENTS,
      action: "Drag-and-Drop to Upload",
    },
    UPLOAD: {
      category: CATEGORIES.MPS_ATTACHMENTS,
      action: "Click to Upload",
    },
    DOWNLOAD_ZIP: {
      category: CATEGORIES.MPS_ATTACHMENTS,
      action: "Download Zip",
    },
  },
  MPS: {
    CHECK_OFFER: {
      category: CATEGORIES.MPS,
      action: "Check offer from MPS",
    },
    CHECK_STATUS: {
      category: CATEGORIES.MPS,
      action: "Check status of MPS request",
    },
    REQUEST_DAMAGE_ASSESSMENT: {
      category: CATEGORIES.MPS,
      action: "Request Damage Assessment",
    },
    GO_TO_MPS: {
      category: CATEGORIES.MPS,
      action: "Go to mpsbilskade.no",
    },
    SKIP: {
      category: CATEGORIES.MPS,
      action: "Skip Mps page",
    },
  },
  HEADER: {
    BECOME_MEMBER: {
      category: CATEGORIES.HEADER,
      action: "Become Member",
    },
    LOG_IN: {
      category: CATEGORIES.HEADER,
      action: "Log In",
    },
    LOG_OUT: {
      category: CATEGORIES.HEADER,
      action: "Log Out",
    },
    PREVIEW: {
      category: CATEGORIES.HEADER,
      action: "Open Preview",
    },
    FAQ: {
      category: CATEGORIES.HEADER,
      action: "Open FAQ",
    },
  },
  VERIFICATION: {
    SEND_SMS: {
      category: CATEGORIES.VERIFICATION,
      action: "Send SMS",
    },
    RESEND_SMS: {
      category: CATEGORIES.VERIFICATION,
      action: "Resend SMS",
    },
    RESEND_SMS_ATTEMPT_CAP: {
      category: CATEGORIES.VERIFICATION,
      action: "Resend SMS, Too Many Attempts",
    },
    VERIFY_ONETIMECODE: {
      category: CATEGORIES.VERIFICATION,
      action: "Verify OneTimeCode",
    },
    CHANGE_NUMBER: {
      category: CATEGORIES.VERIFICATION,
      action: "Change Phonenumber",
    },
  },
};
