import {
  GetProductsResponse,
  ProductType,
} from "@dtp/membership-service-types";
import { addBreadcrumb } from "@sentry/browser";
import { useCallback, useMemo } from "react";
import { ApiError, HttpVerb } from "../api/ApiClient";
import { useAutoLoadingClientBase } from "../api/ClientBase";
import { useAuth } from "../auth/AuthProvider";

export interface IMembershipServiceClient {
  send<T>(
    method: HttpVerb,
    uri: string,
    content?: any,
    headers?: { [key: string]: string }
  ): Promise<T | null>;
}

export function useMembershipServiceClient(): IMembershipServiceClient {
  const auth = useAuth();
  const options =
    window.location.host === "skademelding.naf.no"
      ? {
          host: "https://api.naf.no/dtp-contenthub-membershipapi",
          subscriptionKey: "1fc5e7c364104d5db53afe1423dd066c",
        }
      : {
          host: "https://dev-api.naf.no/dtp-contenthub-membershipapi-test",
          subscriptionKey: "f810c9d4673948f49be314afb6ce7e71",
        };

  const apimSubscriptionKeyHeader = useMemo(
    () => ({ "Ocp-Apim-Subscription-Key": options.subscriptionKey }),
    [options.subscriptionKey]
  );

  const jwt = auth?.jwt;
  const authorizationHeader = useMemo(
    () => (jwt ? { Authorization: `Bearer ${jwt}` } : null),
    [jwt]
  );

  const doFetch = useCallback(
    (uri: string, init?: RequestInit) => {
      addBreadcrumb({
        category: "FetchData",
        data: {
          uri,
          init,
        },
      });
      return fetch(`${options.host}/${uri}`, init);
    },
    [options.host]
  );

  const send = useCallback(
    async function <T>(
      method: HttpVerb,
      uri: string,
      content?: any,
      headers?: { [key: string]: string }
    ) {
      const allHeaders = {
        ...headers,
        ...apimSubscriptionKeyHeader,
        ...authorizationHeader,
      };

      const initBase =
        content !== undefined
          ? {
              headers: {
                "content-type": "application/json",
                ...allHeaders,
              },
              body: JSON.stringify(content),
            }
          : { headers: allHeaders };

      const init: RequestInit = {
        ...initBase,
        method,
      };

      const response = await doFetch(uri, init);

      if (response.status === 404 && method === "GET") return null;

      const contentType = response.headers.get("content-type");

      const result =
        contentType && contentType.indexOf("application/json") !== -1
          ? await response.json()
          : await response.text();

      const statusString = response.status.toString();

      if (!statusString.startsWith("2")) {
        const error = new ApiError(
          `Status code is not OK: ${response.statusText}. ${
            result?.error || ""
          }`,
          result,
          response.status
        );

        throw error;
      }

      return result as T;
    },
    [apimSubscriptionKeyHeader, authorizationHeader, doFetch]
  );
  const membershipServiceClient = useMemo(() => ({ send }), [send]);
  return membershipServiceClient;
}

export interface IMembershipProductClient {
  isLoading: boolean;
  error: ApiError | null;
  result: ProductType | undefined;
}

export function useMembershipServiceProducts() {
  const { send } = useMembershipServiceClient();
  return useAutoLoadingClientBase(
    useCallback(() => send<GetProductsResponse>("GET", "v2/products"), [send])
  );
}

export function useMembershipProductClient(
  productNumber: string
): IMembershipProductClient {
  const products = useMembershipServiceProducts();
  if (products.result) {
    const product = products.result.customerProducts.eligibleProducts.find(
      (p) => p.productNumber === productNumber
    );
    if (product)
      return {
        isLoading: false,
        error: products.error,
        result: product,
      };
    return {
      isLoading: false,
      error: products.error,
      result: undefined,
    };
  }
  return {
    isLoading: products.isLoading,
    error: products.error,
    result: undefined,
  };
}
