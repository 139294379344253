export type Cancelable<T> = {
  isCanceled: boolean;
  promise: Promise<T>;
  cancel: () => void;
};

export class CancellationToken {
  private _isCanceled = false;

  get isCanceled() {
    return this._isCanceled;
  }

  cancel() {
    this._isCanceled = true;
  }
}

export function makeCancelable<T>(promise: Promise<T>): Cancelable<T> {
  const context: Partial<Cancelable<T>> = {
    isCanceled: false,
  };

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) =>
        context.isCanceled ? reject({ isCanceled: true }) : resolve(val),
      (error) =>
        context.isCanceled ? reject({ isCanceled: true }) : reject(error)
    );
  });

  context.promise = wrappedPromise;
  context.cancel = () => (context.isCanceled = true);

  return context as Cancelable<T>;
}
