import "@teamscheme/global-style/lib/Fonts.css";
import { lazy, Suspense } from "react";
import styled from "styled-components";
import { StaticLoadingScreen } from "./shared/screens/LoadingScreen";
import {
  Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { LayoutContextProvider as LayoutProvider } from "./shared/layout/LayoutContext";
import StaticRoutes from "./shared/static-pages/StaticRoutes";
import { createBrowserHistory } from "history";
import { ApiClientProvider } from "./api/ApiClient";
import { WebSocketClientProvider } from "./api/WebSocketClient";
import { BottomPanelProvider } from "./shared/layout/BottomPanel";
import { AuthProvider } from "./auth/AuthProvider";
import CustomerProvider from "./auth/CustomerProvider";
import { HeaderWithProgressProvider } from "./shared/layout/header/HeaderWithProgress";
import { CustomizedThemeProvider as ThemeProvider } from "@teamscheme/theme";
import { GlobalStyle } from "@teamscheme/global-style";
import { AppTrackingProvider } from "./shared/Tracking";
import { DisableBodyScrollProvider } from "@teamscheme/disable-body-scroll";
import Providers from "./shared/Providers";
import LoadingBox from "./shared/LoadingBox";
import { NotFoundPage } from "./shared/screens/ErrorScreen";
import { TrackPageViews } from "./report/ReportRoutes";

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const history = createBrowserHistory();

const LoginCallbackPage = lazy(() => import("./auth/LoginCallbackPage"));
const LogoutCallbackPage = lazy(() => import("./auth/LogoutCallbackPage"));
const AdminRoute = lazy(() => import("./admin/Admin"));

const PROVIDERS = [
  AppTrackingProvider,
  DisableBodyScrollProvider,
  LayoutProvider,
  TrackPageViews,
  ApiClientProvider,
  BottomPanelProvider,
  WebSocketClientProvider,
  AppContainer,
  AuthProvider,
  CustomerProvider,
  HeaderWithProgressProvider,
];

function App() {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <Router history={history}>
        <Suspense fallback={<LoadingBox />}>
          <Switch>
            <Route path="/admin" component={AdminRoute} />
            <Route>
              <Providers providers={PROVIDERS}>
                <Route>
                  <Suspense fallback={<StaticLoadingScreen />}>
                    <Switch>
                      <Route
                        path="/t/:token/:path*"
                        component={ReportShortUrl}
                      />
                      <Route
                        path="/login/callback"
                        component={LoginCallbackPage}
                      />
                      <Route
                        path="/logout/callback"
                        component={LogoutCallbackPage}
                      />
                      {Object.values(StaticRoutes).map((x) => (
                        <Route
                          key={x.path}
                          path={x.path}
                          component={x.component}
                        />
                      ))}
                      <Route>
                        <NotFoundPage />
                      </Route>
                    </Switch>
                  </Suspense>
                </Route>
              </Providers>
              <Footer>
                <p>
                  &copy; {new Date().getFullYear()} NAF{" "}
                  <small>v{process.env.REACT_APP_VERSION}</small>
                </p>
              </Footer>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

function ReportShortUrl() {
  const match = useRouteMatch<{ token: string; path: string }>();
  const { token, path } = match?.params;
  const p = path
    ? `${StaticRoutes.report.path}/${path}`
    : StaticRoutes.report.path;
  const next = `${p}?token=${token}`;
  return <Redirect to={next} />;
}

const Footer = styled.footer`
  p {
    margin: 0;
    padding: 0.5em 1em;
    ${({ theme }) => theme.fontStyle.ui.tiny};
  }
`;

export default App;
