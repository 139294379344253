import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { PageContainer, PageProps, PageRef } from "../layout/Page";
import styled from "styled-components";
import { onDesktop } from "@teamscheme/theme";
import { useHistory } from "react-router-dom";
import StaticHeader from "../layout/header/StaticHeader";
import PageFooter from "../layout/PageFooter";
import {
  ClickBackLink,
  LinkBackLink,
} from "@teamscheme/page-navigation-footer";
import StaticRoutes from "./StaticRoutes";

export const AboutContent = styled.div`
  max-width: 40em;
  ${({ theme }) => onDesktop`
    padding: ${theme.spacing.space24} ${theme.spacing.space48};
  `}
`;

export const StaticPageElement = styled((x: PageProps) => (
  <StaticPage {...x} />
))`
  h1 {
    text-align: center;
  }
`;

export interface AboutPageProps {
  title: string;
  children: ReactNode;
  backTo?: string;
}

export function BackLink({
  backTo,
  ...props
}: { backTo?: string } & Omit<HTMLAttributes<HTMLAnchorElement>, "onClick">) {
  const history = useHistory();
  const label = "Tilbake";
  return backTo ? (
    <LinkBackLink to={backTo} {...props}>
      {label}
    </LinkBackLink>
  ) : history.length > 1 ? (
    <ClickBackLink onClick={() => history.goBack()} {...props}>
      {label}
    </ClickBackLink>
  ) : (
    <LinkBackLink to={StaticRoutes.landing.path} {...props}>
      {label}
    </LinkBackLink>
  );
}

export const GreenStaticHeader = styled(StaticHeader)`
  background-color: ${({ theme }) => theme.nafColor.primary.dew};
`;

export const GreenPageContainer = styled(PageContainer)`
  background-color: ${({ theme }) => theme.nafColor.primary.dew};
`;

export interface StaticPageProps extends PageProps {
  greenHeader?: boolean;
  greenBackground?: boolean;
}

const StaticPage = forwardRef<PageRef, StaticPageProps>(
  (
    {
      children,
      greenHeader = false,
      greenBackground = false,
      footer = false,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        {greenHeader ? <GreenStaticHeader /> : <StaticHeader />}
        {greenBackground ? (
          <GreenPageContainer ref={ref} {...rest}>
            {children}
          </GreenPageContainer>
        ) : (
          <PageContainer ref={ref} {...rest}>
            {children}
          </PageContainer>
        )}
        {footer ? <PageFooter /> : null}
      </>
    );
  }
);

export default StaticPage;
