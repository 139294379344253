import React from "react";
import styled from "styled-components";
import FlyoutModal from "@teamscheme/flyout-modal";
import { FaqPageContent } from "../../../about/FaqPage";

export interface FaqModalProps {
  isOpen: boolean;
  onClose(): void;
}

const FaqMenuModal = styled(FlyoutModal)`
  width: 100%;
`;

export default function FaqModal({ isOpen, onClose }: FaqModalProps) {
  return (
    <FaqMenuModal isOpen={isOpen} onClose={onClose}>
      <h1>Vanlige Spørsmål</h1>
      <FaqPageContent />
    </FaqMenuModal>
  );
}
