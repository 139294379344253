import React, { ReactNode } from "react";
import styled from "styled-components";
import InputComponent from "./InputComponent";
import InputDescription from "./InputDescription";
import InfoButtonModal from "./InfoButtonModal";

export type LabledInputComponentProps = {
  name: string;
  label: ReactNode;
  description?: string;
  children?: React.ReactNode;
  hasError?: boolean;
  className?: string;
};

const InputComponentLabelRow = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.space8};
  display: inline-flex;
  align-items: center;

  label {
    ${(props) => props.theme.fontStyle.bodyBold}

    em {
      text-decoration: underline;
    }
  }
`;

export default function LabeledInputComponent({
  className,
  name,
  label,
  description,
  hasError,
  children,
}: LabledInputComponentProps) {
  return (
    <div className={className}>
      <InputComponentLabelRow>
        <label htmlFor={name}>{label}</label>
        {description ? (
          <InfoButtonModal>
            <h3>{label}</h3>
            <InputDescription description={description} />
          </InfoButtonModal>
        ) : null}
      </InputComponentLabelRow>
      <InputComponent hasError={hasError}>{children}</InputComponent>
    </div>
  );
}
