import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import jwtDecode from "jwt-decode";
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-spa-js";
import { useSentryTag, useTrackContext } from "../shared/Tracking";
import { useHistory } from "react-router-dom";
import { LocationDescriptor } from "history";
import LoadingBox from "../shared/LoadingBox";

export interface Claims {
  customerGuid: string;
  email: string;
}
export interface LoginOptions {
  targetUrl: string;
  locationState?: any;
  options?: Omit<RedirectLoginOptions, "appState" | "redirect_uri">;
}

export interface IAuth0Context {
  isAuthenticated: boolean;
  claims: Claims | null;
  appState: any | null;
  jwt: string | null;
  loginWithRedirect(props: LoginOptions): void;
  logout(): void;
  handleRedirectCallback(): Promise<void>;
  refetchToken(): void;
}

export const Auth0Context = createContext<IAuth0Context | null>(null);

const options =
  window.location.host === "skademelding.naf.no"
    ? {
        domain: "id.naf.no",
        client_id: "gb0FQ6JoSghU91rDw7rLP1g5bIYmQrQU",
      }
    : {
        domain: "stage.id.naf.no",
        client_id: "SaS8YIPKlTKSewtyoiXEsojEHTq6CFBr",
      };

const initOptions: Auth0ClientOptions = {
  ...options,
  redirect_uri: `${document.location.origin}/login/callback`,
  useCookiesForTransactions: true,
};

export function useAuth() {
  const auth0Context = useContext(Auth0Context);
  if (!auth0Context) throw new Error("No Auth0Context found!");
  return auth0Context;
}

export function useAuthorizationHeader() {
  const { jwt } = useAuth();

  return useMemo(() => ({ Authorization: `Bearer ${jwt}` }), [jwt]);
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const { push: pushHistory } = useHistory();
  const onRedirectCallback = useCallback(
    function (appState?: any) {
      const location: LocationDescriptor = {
        pathname: window.location.pathname,
        state: null,
        search: undefined,
      };
      if (appState) {
        if ("targetUrl" in appState) {
          const [path, search] = appState.targetUrl.split("?");
          location.pathname = path;
          location.search = search ? `?${search}` : undefined;
        }
        if ("locationState" in appState) {
          location.state = appState.locationState;
        }
      }
      pushHistory(location);
    },
    [pushHistory]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [jwt, setJwt] = useState<string | null>(null);
  const [auth0Client, setAuth0] = useState<Auth0Client | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [appState, setAppState] = useState<any | null>(null);

  const initializeToken = useCallback(async function initializeToken(
    auth0Client: Auth0Client
  ) {
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (!isAuthenticated) return;

    const claims = await auth0Client.getIdTokenClaims();
    const id_token = claims!.__raw;
    if (id_token) {
      setJwt(id_token);
    }

    setIsAuthenticated(isAuthenticated);
  },
  []);

  useEffect(() => {
    const initAuth0 = async () => {
      setIsLoading(true);

      const auth0FromHook = await createAuth0Client({
        ...initOptions,
      });

      await auth0FromHook.checkSession();

      setAuth0(auth0FromHook);

      await initializeToken(auth0FromHook);

      setIsLoading(false);
    };

    initAuth0();
  }, [initializeToken]);

  const loginWithRedirect = useCallback(
    ({ targetUrl, locationState, options }: LoginOptions) =>
      auth0Client &&
      auth0Client.loginWithRedirect({
        ...options,
        redirect_uri: `${document.location.origin}/login/callback`,
        appState: {
          locationState,
          targetUrl,
        },
      }),
    [auth0Client]
  );

  const refetchToken = useCallback(async () => {
    if (!auth0Client) {
      return;
    }
    await auth0Client.getTokenSilently({ ignoreCache: true });
    await initializeToken(auth0Client);
  }, [auth0Client, initializeToken]);

  const handleRedirectCallback = useCallback(
    async function () {
      if (!auth0Client) return;
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();
        onRedirectCallback(appState);
        setAppState(appState);
        await initializeToken(auth0Client);
      } else if (window.location.search.includes("success=true")) {
        await loginWithRedirect({ targetUrl: "/" });
      }
    },
    [auth0Client, loginWithRedirect, initializeToken, onRedirectCallback]
  );

  const claims = useMemo(() => {
    if (!jwt) return null;
    const { email, "https://id.naf.no/customScopes/customerId": customerGuid } =
      jwtDecode<any>(jwt);
    return { email, customerGuid };
  }, [jwt]);

  useSentryTag("isAuthenticated", isAuthenticated);
  useTrackContext("isAuthenticated", isAuthenticated);

  if (isLoading || !auth0Client) return <LoadingBox />;

  const context = {
    jwt,
    claims,
    isAuthenticated,
    appState,
    loginWithRedirect,
    logout: () => {
      if (!auth0Client) return;
      const { origin, pathname, search } = document.location;
      const query = new URLSearchParams();
      query.set("returnTo", `${pathname}${search}`);
      const returnTo = `${origin}/logout/callback?${query.toString()}`;
      auth0Client.logout({ returnTo });
    },
    handleRedirectCallback,
    refetchToken,
  };

  return (
    <Auth0Context.Provider value={context}>{children}</Auth0Context.Provider>
  );
}
