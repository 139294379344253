import { lazy } from "react";
import { PATH as SPLIT_PATH } from "../../report/locked-report/SplitReportLocation";
import { REPORT_PATH } from "../../report/ReportRoutes";

const StaticRoutes = {
  faq: {
    path: "/om/sporsmal-og-svar",
    component: lazy(() => import("../../about/FaqPage")),
  },
  privacy: {
    path: "/om/cookies-og-personvern",
    component: lazy(() => import("../../about/PrivacyPage")),
  },
  terms: {
    path: "/om/vilkar",
    component: lazy(() => import("../../about/TermsPage")),
  },
  digitalSigning: {
    path: "/om/digital-signering",
    component: lazy(() => import("../../about/DigitalSigningPage")),
  },
  membershipPayment: {
    path: "/bli-medlem/callback/:stateId",
    component: lazy(() => import("../../membership/MembershipPaymentPage")),
  },
  becomeMemberForm: {
    path: "/bli-medlem/:productNumber",
    component: lazy(() => import("../../membership/BecomeMemberFormPage")),
  },
  becomeMember: {
    path: "/bli-medlem",
    component: lazy(() => import("../../membership/BecomeMemberPage")),
  },
  createNew: {
    path: "/opprett",
    component: lazy(
      () => import("../../report/landing/verification/CreateNewPage")
    ),
  },
  split: {
    path: SPLIT_PATH,
    component: lazy(() => import("../../report/locked-report/SplitReportPage")),
  },
  report: {
    path: REPORT_PATH,
    component: lazy(() => import("../../report/ReportApp")),
  },
  landing: {
    path: "/",
    component: lazy(() => import("../../report/landing/LandingPage")),
  },
};

export default StaticRoutes;
