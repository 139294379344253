import { createContext, ReactNode, useState } from "react";

export interface ILayoutContext {
  paddingBottom: number;
  setPaddingBottom: (padding: number) => void;
}

const LayoutContext = createContext<ILayoutContext | null>(null);

export function LayoutContextProvider({ children }: { children: ReactNode }) {
  const [paddingBottom, setPaddingBottom] = useState(0);
  return (
    <LayoutContext.Provider
      value={{
        setPaddingBottom,
        paddingBottom,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutContext;
