import { DateString, VehicleKey } from "./AccidentReport";

export enum FigureId {
  CarA = "carA",
  CarB = "carB",
  McA = "mcA",
  McB = "mcB",
  TruckA = "truckA",
  TruckB = "truckB",
}

export type Point = [number, number];
export type Segment = [Point, Point];
export type DecodedBrush = [string, number];
export type DecodedPolyline = [number[], DecodedBrush | null];
export type KeyedPolyline = { id: string; polyline: DecodedPolyline };
export type Dimensions = [number, number];

export interface FigureOptions {
  size: Dimensions;
}

const FIGURE_OPTIONS: { [figureId in FigureId]: FigureOptions } = {
  [FigureId.CarA]: { size: [25, 60] },
  [FigureId.CarB]: { size: [25, 60] },
  [FigureId.McA]: { size: [29, 60] },
  [FigureId.McB]: { size: [29, 60] },
  [FigureId.TruckA]: { size: [29, 60] },
  [FigureId.TruckB]: { size: [29, 60] },
};

export function getFigureOptions(figureId: FigureId): FigureOptions {
  return FIGURE_OPTIONS[figureId];
}

export enum SketchActionCommand {
  AddPolyline = "AddPolyline",
  DeletePolyline = "DeletePolyline",
  AddFigure = "AddFigure",
  MoveFigure = "MoveFigure",
  DeleteFigure = "DeleteFigure",
}

export interface AddPolyline {
  line: string;
  brush: EncodedBrush;
}
export interface AddFigure {
  figureId: FigureId;
  figurePlacement: FigurePlacement;
}
export interface MoveFigure {
  figurePlacement: FigurePlacement;
}

export interface SketchActionBase<T extends SketchActionCommand>
  extends ISketchElement {
  actionId: string;
  elementId: string;
  layerId: string;
  command: T;
}

export interface AddPolylineAction
  extends SketchActionBase<SketchActionCommand.AddPolyline> {
  addPolyline: AddPolyline;
}

export interface DeletePolylineAction
  extends SketchActionBase<SketchActionCommand.DeletePolyline> {}

export interface AddFigureAction
  extends SketchActionBase<SketchActionCommand.AddFigure> {
  addFigure: AddFigure;
}

export interface MoveFigureAction
  extends SketchActionBase<SketchActionCommand.MoveFigure> {
  moveFigure: MoveFigure;
}

export interface DeleteFigureAction
  extends SketchActionBase<SketchActionCommand.DeleteFigure> {}

export type SketchAction =
  | AddPolylineAction
  | DeletePolylineAction
  | AddFigureAction
  | MoveFigureAction
  | DeleteFigureAction;

export interface Figures {
  [id: string]: Figure;
}

export interface EncodedKeyedPolylines {
  [id: string]: EncodedKeyedPolyline;
}
export interface Sketch {
  polylines: DecodedPolyline[];
  actions: SketchAction[];
}

export interface EncodedSketch {
  polylines: EncodedPolyline[];
  actions: SketchAction[];
  version: string;
}

export const DEFAULT_BRUSH: DecodedBrush = ["black", 3];

export interface EncodedBrush {
  color: string;
  size: number;
}

export interface ISketchElement {
  created?: DateString;
  createdBy?: VehicleKey;
}

export interface FigurePlacement {
  scale: number;
  angle: number;
  x: number;
  y: number;
}

export interface Figure extends ISketchElement {
  figureId: FigureId;
  placement: FigurePlacement;
}

export interface EncodedPolyline extends ISketchElement {
  line: string | null;
  brush: EncodedBrush | null;
}

export interface EncodedKeyedPolyline {
  id: string;
  polyline: EncodedPolyline;
}

export function serializeBrush(
  brush: DecodedBrush | null
): EncodedBrush | null {
  return brush
    ? {
        color: brush[0],
        size: brush[1],
      }
    : null;
}

export function parseBrush(brush: EncodedBrush | null): DecodedBrush | null {
  return brush ? [brush.color, brush.size] : null;
}

export module Common {
  export function parsePolyline(
    polyline: EncodedPolyline,
    b64toFloatArray: (source: string) => Float32Array
  ): DecodedPolyline | null {
    if (!polyline.line) return null;

    const floatArray = b64toFloatArray(polyline.line);
    const array = Array.prototype.slice.call(floatArray) as number[];
    const decoded: DecodedPolyline = [array, parseBrush(polyline.brush)];
    return decoded;
  }

  export function parseKeyedPolyline(
    keyedPolyline: EncodedKeyedPolyline,
    b64toFloatArray: (source: string) => Float32Array
  ): KeyedPolyline | null {
    const decoded = parsePolyline(keyedPolyline.polyline, b64toFloatArray);
    return decoded ? { id: keyedPolyline.id, polyline: decoded } : null;
  }

  export function parseSketch(
    sketch: EncodedSketch,
    b64toFloatArray: (source: string) => Float32Array
  ): Sketch {
    return {
      polylines: sketch.polylines
        .map((x) => parsePolyline(x, b64toFloatArray))
        .filter((x): x is DecodedPolyline => !!x),
      actions: sketch.actions,
    };
  }
}

export module Browser {
  function b64toFloatArray(str: string) {
    const blob = atob(str);
    const arrayBuffer = new ArrayBuffer(blob.length);
    const dataView = new DataView(arrayBuffer);
    for (let i = 0; i < blob.length; i++) {
      dataView.setUint8(i, blob.charCodeAt(i));
    }
    return new Float32Array(arrayBuffer);
  }

  export function parseSketch(sketch: EncodedSketch) {
    return Common.parseSketch(sketch, b64toFloatArray);
  }

  export function parsePolyline(polyline: EncodedPolyline) {
    return Common.parsePolyline(polyline, b64toFloatArray);
  }

  export function serializePolyline(
    polyline: DecodedPolyline
  ): EncodedPolyline {
    const str = btoa(
      String.fromCharCode.apply(
        null,
        new Uint8Array(new Float32Array(polyline[0]).buffer) as any
      )
    );
    return {
      line: str,
      brush: serializeBrush(polyline[1]),
    };
  }

  export function serializeKeyedPolyline(
    polyline: KeyedPolyline
  ): EncodedKeyedPolyline {
    return {
      id: polyline.id,
      polyline: serializePolyline(polyline.polyline),
    };
  }
}

export module Node {
  declare class Buffer {
    static from(str: string, encoding?: "base64"): Buffer;
    readonly buffer: ArrayBuffer;
    readonly byteLength: number;
    readonly byteOffset: number;
  }

  function pareFloats(x: string) {
    const decoded = Buffer.from(x, "base64");
    return new Float32Array(
      decoded.buffer,
      decoded.byteOffset,
      decoded.byteLength / 4
    );
  }

  export function parseSketch(sketch: EncodedSketch) {
    return Common.parseSketch(sketch, pareFloats);
  }

  export function parsePolyline(polyline: EncodedPolyline) {
    return Common.parsePolyline(polyline, pareFloats);
  }
}

export function getActiveLayer(sketch: Sketch): string | null {
  if (!sketch.actions.length) return null;
  return sketch.actions[sketch.actions.length - 1].layerId;
}

export function getActions(sketch: Sketch, layerId: string) {
  return sketch.actions.filter((x) => x.layerId === layerId);
}

export interface Element<T> {
  elementId: string;
  value: T;
}

export function getPolylineElements(actions: SketchAction[]) {
  const addedPolylines = actions.filter(
    (x): x is AddPolylineAction => x.command === SketchActionCommand.AddPolyline
  );
  const deletedPolylines = actions.filter(
    (x): x is DeletePolylineAction =>
      x.command === SketchActionCommand.DeletePolyline
  );
  return addedPolylines
    .filter((x) => !deletedPolylines.find((y) => y.elementId === x.elementId))
    .map<Element<EncodedPolyline>>((x) => {
      const value = {
        line: x.addPolyline.line,
        brush: x.addPolyline.brush,
        created: x.created,
        createdBy: x.createdBy,
      };

      return { elementId: x.elementId, value };
    });
}

export function getFigureElements(actions: SketchAction[]) {
  const addedFigures = actions.filter(
    (x): x is AddFigureAction => x.command === SketchActionCommand.AddFigure
  );
  const deletedFigures = actions.filter(
    (x): x is DeleteFigureAction =>
      x.command === SketchActionCommand.DeleteFigure
  );
  const movedFigures = actions
    .filter(
      (x): x is MoveFigureAction => x.command === SketchActionCommand.MoveFigure
    )
    .reverse();
  return addedFigures
    .filter((x) => !deletedFigures.find((y) => y.elementId === x.elementId))
    .map<Element<Figure>>((x) => {
      const lastMovement = movedFigures.find(
        (y) => y.elementId === x.elementId
      );

      const value = {
        figureId: x.addFigure.figureId,
        placement: lastMovement
          ? lastMovement.moveFigure.figurePlacement
          : x.addFigure.figurePlacement,
      };

      return {
        elementId: x.elementId,
        value,
      };
    });
}
