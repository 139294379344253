import React, { HtmlHTMLAttributes } from "react";
import styled from "styled-components";
import NafLogo from "../logos/NafLogo";
import { onDesktop, onMinWidthXtraLarge } from "@teamscheme/theme";
import { EVENTS } from "../Tracking";
import StaticRoutes from "../static-pages/StaticRoutes";
import { ButtonLink, ExternalButtonLink } from "@teamscheme/button-link";

const PageFooterWrapper = styled.div`
  background: ${({ theme }) => theme.nafColor.primary.spruce};
  color: ${({ theme }) => theme.nafColor.signature.white};
`;

const PageFooterContent = styled.div`
  position: relative;
  max-width: ${(props) => props.theme.skeleton.maxWidth};
  padding: ${({ theme }) =>
    `${theme.spacing.space64} ${theme.spacing.space24} ${theme.spacing.space40}`};
  margin: auto;

  ${({ theme }) =>
    onDesktop`
    padding: ${theme.spacing.space80} ${theme.spacing.space24} ${theme.spacing.space64}
  `}
`;

const PageFooterLinkDescription = styled.span`
  font-size: ${({ theme }) => theme.spacing.space16};
  margin-bottom: -${({ theme }) => theme.spacing.space8};
  ${({ theme }) => theme.fontStyle.article.bodySubHeader}
`;

const InlineBlockDiv = styled.div`
  display: inline-block;
`;

const PageFooterLinkWrapper = styled(
  (props: HtmlHTMLAttributes<HTMLDivElement>) => {
    const { children, ...rest } = props;
    return (
      <div {...rest}>
        <InlineBlockDiv>{children}</InlineBlockDiv>
      </div>
    );
  }
)`
  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing.space8};
  }
`;

const FooterSectionHeader = styled.h3`
  ${({ theme }) => theme.fontStyle.headers.header3};
  margin: 0 0 ${({ theme }) => theme.spacing.space24};
`;

const AccidentStatementLinksWrapper = styled.section`
  grid-area: accidentStatementLinks;
`;
const UsefulFromNafLinksWrapper = styled.section`
  grid-area: usefulFromNafLinks;
`;
const HelpLinksWrapper = styled.section`
  grid-area: helpLinks;
`;

const PageFooterBody = styled.div`
  display: grid;
  grid-template-areas:
    "fillOutAccidentStatement"
    "accidentStatementLinks"
    "usefulFromNafLinks"
    "helpLinks";
  grid-template-rows: repeat(4, auto);
  grid-template-columns: 1fr;

  section {
    margin-bottom: ${({ theme }) => theme.spacing.space56};
  }

  ${({ theme }) => onDesktop`
  grid-template-areas:
    "fillOutAccidentStatement fillOutAccidentStatement ."
    "accidentStatementLinks usefulFromNafLinks helpLinks";
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${theme.spacing.space12};
  `}

  ${onMinWidthXtraLarge`
    grid-template-areas: "accidentStatementLinks usefulFromNafLinks helpLinks fillOutAccidentStatement";
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const HorizontalSeparator = styled.hr`
  border-color: ${({ theme }) => theme.nafColor.primary.park};
  margin: ${({ theme }) =>
    `${theme.spacing.space32} auto ${theme.spacing.space16}`};
`;

const CopyrightSpan = styled.span`
  ${({ theme }) => theme.fontStyle.ui.details};
  margin-right: 0;
  margin-bottom: ${({ theme }) => theme.spacing.space16};

  ${({ theme }) =>
    onDesktop`
      margin-right: ${theme.spacing.space40};
      margin-bottom: 0;
    `};
`;
const CopyrightPrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${onDesktop`
    flex-direction: row;
    align-items: center;
  `}
`;

function AccidentStatementLinks() {
  return (
    <AccidentStatementLinksWrapper>
      <FooterSectionHeader>NAF Digital skademelding</FooterSectionHeader>
      <PageFooterLinkWrapper>
        <ButtonLink variant="negative" linkTo={StaticRoutes.faq.path}>
          Vanlige spørsmål
        </ButtonLink>
      </PageFooterLinkWrapper>
      <PageFooterLinkWrapper>
        <ButtonLink variant="negative" linkTo={StaticRoutes.terms.path}>
          Vilkår
        </ButtonLink>
      </PageFooterLinkWrapper>
    </AccidentStatementLinksWrapper>
  );
}

function UsefulFromNafLinks() {
  return (
    <UsefulFromNafLinksWrapper>
      <FooterSectionHeader>Nyttig fra NAF</FooterSectionHeader>
      <PageFooterLinkWrapper>
        <ExternalButtonLink
          variant="negative"
          href="https://www.naf.no/medlemskap/medlemsfordeler/naf-veihjelp/"
        >
          Veihjelp
        </ExternalButtonLink>
      </PageFooterLinkWrapper>

      <PageFooterLinkWrapper>
        <ExternalButtonLink
          variant="negative"
          href="https://www.naf.no/medlemskap/medlemsfordeler/juridisk-radgivning/"
        >
          Juridisk rådgivning
        </ExternalButtonLink>
      </PageFooterLinkWrapper>
    </UsefulFromNafLinksWrapper>
  );
}

const TwoLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function HelpLinks() {
  return (
    <HelpLinksWrapper>
      <FooterSectionHeader>Trenger du hjelp?</FooterSectionHeader>
      <TwoLineWrapper>
        <PageFooterLinkDescription>
          Ring oss (8-16 virkedager):
        </PageFooterLinkDescription>
        <PageFooterLinkWrapper>
          <ButtonLink variant="negative" href="tel:08505">
            08 505
          </ButtonLink>
        </PageFooterLinkWrapper>
      </TwoLineWrapper>
      <TwoLineWrapper>
        <PageFooterLinkDescription>
          Send oss en e-post:
        </PageFooterLinkDescription>
        <PageFooterLinkWrapper>
          <ButtonLink
            variant="negative"
            href="mailto:kundeservice@naf.no"
            trackEvent={EVENTS.FOOTER.EMAIL_CUSTOMER_SERVICE}
          >
            kundeservice@naf.no
          </ButtonLink>
        </PageFooterLinkWrapper>
      </TwoLineWrapper>
      <TwoLineWrapper>
        <PageFooterLinkDescription>Fra utlandet:</PageFooterLinkDescription>
        <PageFooterLinkWrapper>
          <ButtonLink variant="negative" href="tel:004723213100">
            +47 23 21 31 00
          </ButtonLink>
        </PageFooterLinkWrapper>
      </TwoLineWrapper>
    </HelpLinksWrapper>
  );
}

export default function PageFooter() {
  return (
    <PageFooterWrapper>
      <PageFooterContent>
        <PageFooterBody>
          <AccidentStatementLinks />
          <UsefulFromNafLinks />
          <HelpLinks />
        </PageFooterBody>
        <div>
          <NafLogo alt="" size={56} />
          <HorizontalSeparator />
          <CopyrightPrivacyWrapper>
            <CopyrightSpan>© Norges Automobil-Forbund</CopyrightSpan>
            <ButtonLink
              variant="negative"
              size="small"
              linkTo={StaticRoutes.privacy.path}
            >
              Personvern og cookies
            </ButtonLink>
          </CopyrightPrivacyWrapper>
        </div>
      </PageFooterContent>
    </PageFooterWrapper>
  );
}
