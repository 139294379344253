import {
  ComponentType,
  createContext,
  lazy,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useReport } from "./ReportContext";
import {
  CommonSection,
  PageTwoSection,
  SectionParent,
  VehicleSection,
} from "@accident-statement/schema";
import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTracking } from "../shared/Tracking";
import { PageCounter as StepCounter } from "../shared/layout/Page";
import styled from "styled-components";
import {
  TextLink,
  TextLinkRouterLinkElementProps,
} from "@teamscheme/text-link";
import { LocationDescriptorObject } from "history";

export enum ReportPagePath {
  Report = "Report",
  Vehicle = "Vehicle",
  Driver = "Driver",
  Insurance = "Insurance",
  Accident = "Accident",
  Sketch = "Sketch",
  Attachments = "Attachments",
  Witness = "Witness",
  Preview = "Preview",
  Sign = "Sign",
  SignCallback = "SignCallback",
  SignatureStatus = "SignatureStatus",
  Confirmation = "Confirmation",
  PageTwoSupplementalInfo = "PageTwoSupplementalInfo",
  PageTwoAccidentDescription = "PageTwoAccidentDescription",
  PageTwoInjury = "PageTwoInjury",
}

enum PageCountType {
  PageOne,
  PageTwo,
}

export const PAGE_TWO_PREFIX = "/side-to";

type Section =
  | {
      parent: SectionParent.Vehicle;
      name: VehicleSection;
    }
  | {
      parent: SectionParent.PageTwo;
      name: PageTwoSection;
    }
  | {
      parent: SectionParent.Common;
      name: CommonSection;
    };

export interface IPath {
  path: string;
  progress: number;
  sections: Section[];
  pageCountType?: PageCountType;
  component: ComponentType;
}

export const PATHS: {
  [path in ReportPagePath]: IPath;
} = {
  [ReportPagePath.Vehicle]: {
    path: "/kjoretoy",
    progress: 0.2,
    sections: [
      { parent: SectionParent.Vehicle, name: "vehicleData" },
      { parent: SectionParent.Vehicle, name: "trailerData" },
    ],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/VehiclePage")),
  },
  [ReportPagePath.Driver]: {
    path: "/forer",
    progress: 0.3,
    sections: [
      { parent: SectionParent.Vehicle, name: "driver" },
      { parent: SectionParent.Vehicle, name: "driverContactInfo" },
    ],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/DriverPage")),
  },
  [ReportPagePath.Insurance]: {
    path: "/forsikring",
    progress: 0.4,
    sections: [
      { parent: SectionParent.Vehicle, name: "insurance" },
      { parent: SectionParent.Vehicle, name: "insuree" },
      { parent: SectionParent.Vehicle, name: "insureeContactInfo" },
    ],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/InsurancePage")),
  },
  [ReportPagePath.Accident]: {
    path: "/hendelsen",
    progress: 0.5,
    sections: [
      { parent: SectionParent.Vehicle, name: "accidentQuestions" },
      { parent: SectionParent.Vehicle, name: "collisionTouch" },
      { parent: SectionParent.Vehicle, name: "damageDescription" },
    ],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/AccidentPage")),
  },
  [ReportPagePath.Sketch]: {
    path: "/tegning",
    progress: 0.6,
    sections: [],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/sketch/SketchPage")),
  },
  [ReportPagePath.Attachments]: {
    path: "/vedlegg",
    progress: 0.7,
    sections: [],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/AttachmentsPage")),
  },
  [ReportPagePath.Witness]: {
    path: "/vitner",
    progress: 0.8,
    sections: [{ parent: SectionParent.Vehicle, name: "witness" }],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/WitnessPage")),
  },
  [ReportPagePath.Preview]: {
    path: "/forhandsvisning",
    progress: 0.9,
    sections: [],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/PreviewPage")),
  },
  [ReportPagePath.SignCallback]: {
    path: "/signer/:hash/callback",
    progress: 1.0,
    sections: [],
    component: lazy(() => import("./page-one/SignCallbackPage")),
  },
  [ReportPagePath.SignatureStatus]: {
    path: "/signer/status",
    progress: 1.0,
    sections: [],
    component: lazy(() => import("./page-one/SignatureStatusPage")),
  },
  [ReportPagePath.Sign]: {
    path: "/signer/:hash",
    progress: 1.0,
    sections: [],
    component: lazy(() => import("./page-one/SignPage")),
  },
  [ReportPagePath.Confirmation]: {
    path: "/bekreftelse",
    progress: 1.0,
    sections: [],
    component: lazy(() => import("./page-one/ConfirmationPage")),
  },
  [ReportPagePath.PageTwoSupplementalInfo]: {
    path: `${PAGE_TWO_PREFIX}/supplerende-info`,
    progress: 1 / 4,
    sections: [],
    pageCountType: PageCountType.PageTwo,
    component: lazy(() => import("./page-two/SupplementalInfoPage")),
  },
  [ReportPagePath.PageTwoAccidentDescription]: {
    path: `${PAGE_TWO_PREFIX}/beskrivelse`,
    progress: 2 / 4,
    sections: [],
    pageCountType: PageCountType.PageTwo,
    component: lazy(() => import("./page-two/AccidentDescriptionPage")),
  },
  [ReportPagePath.PageTwoInjury]: {
    path: `${PAGE_TWO_PREFIX}/personskader`,
    progress: 3 / 4,
    sections: [],
    pageCountType: PageCountType.PageTwo,
    component: lazy(() => import("./page-two/InjureePage")),
  },
  [ReportPagePath.Report]: {
    path: "/",
    progress: 0.1,
    sections: [{ parent: SectionParent.Common, name: "accidentDescription" }],
    pageCountType: PageCountType.PageOne,
    component: lazy(() => import("./page-one/ReportLandingPage")),
  },
};

const PAGE_ONE_PATHS = Object.entries(PATHS).filter(
  (x) => "pageCountType" in x[1] && x[1].pageCountType === PageCountType.PageOne
);
const PAGE_TWO_PATHS = Object.entries(PATHS).filter(
  (x) => "pageCountType" in x[1] && x[1].pageCountType === PageCountType.PageTwo
);

export interface IReportRouteContext {
  reportPagePath: ReportPagePath;
}
export const ReportRouteContext = createContext<IReportRouteContext | null>(
  null
);

export function useReportRouteContext() {
  const context = useContext(ReportRouteContext);

  if (!context) throw new Error("No ReportRouteContext found!");

  return context;
}

export function ReportPageOneCounter() {
  const { reportPagePath } = useReportRouteContext();
  const pathList = PAGE_ONE_PATHS;

  const i = pathList
    .sort((a, b) => a[1].progress - b[1].progress)
    .findIndex((x) => x[0] === reportPagePath);

  if (i === -1) return null;

  return (
    <StepCounter
      n={i + 1}
      of={PAGE_ONE_PATHS.length + 1 /* Confirmation page */}
    />
  );
}

export function ReportPageTwoCounter() {
  const { reportPagePath } = useReportRouteContext();
  const pathList = PAGE_TWO_PATHS;

  const i = pathList
    .sort((a, b) => a[1].progress - b[1].progress)
    .findIndex((x) => x[0] === reportPagePath);

  if (i === -1) return null;

  return <StepCounter n={i + 1} of={PAGE_TWO_PATHS.length} />;
}

export const REPORT_PATH = "/rapport";

export function createReportLocation(
  key: ReportPagePath,
  token: string
): LocationDescriptorObject {
  const { path } = PATHS[key];
  return {
    pathname: `${REPORT_PATH}${path}`,
    search: `?token=${token}`,
  };
}

const keys = Object.keys(PATHS) as ReportPagePath[];

export function useReportLocations(): {
  [key in ReportPagePath]: LocationDescriptorObject;
} {
  const report = useReport();
  const token = "tokenA" in report ? report.tokenA : report.tokenB;

  return useMemo(
    () =>
      keys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: createReportLocation(key, token),
        }),
        {}
      ) as { [path in ReportPagePath]: LocationDescriptorObject },
    [token]
  );
}

export type LinkTo =
  | ((urls: {
      [key in ReportPagePath]: LocationDescriptorObject;
    }) => LocationDescriptorObject)
  | ReportPagePath;

export function useLink(to: LinkTo) {
  const urls = useReportLocations();
  const linkTo = useMemo(() => {
    return typeof to === "function" ? to(urls) : urls[to];
  }, [to, urls]);
  return linkTo;
}

export const PageLink = styled(function ({
  to,
  ...props
}: { to: LinkTo } & Omit<TextLinkRouterLinkElementProps, "linkTo">) {
  const linkTo = useLink(to);
  return <TextLink linkTo={linkTo} {...props} />;
})``;

export function ScrollToTop({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [history]);

  return children as JSX.Element;
}

function normalizePath(pathname: string) {
  return pathname
    .replace(/\/t\/[^/$]+((\/[^/]+)*$)?/, function (_, end) {
      return `/t/:token${end || ""}`;
    })
    .replace(/\/signer\/[^/$]+((\/[^/]+)*$)?/, function (_, end) {
      return `/signer/:hash${end || ""}`;
    });
}

export function TrackPageViews({ children }: { children: ReactNode }) {
  const tracking = useTracking();
  const history = useHistory();
  const trackLocation = useCallback(
    function trackLocation(location: Location) {
      tracking.pageView({
        path: normalizePath(location.pathname) || "/",
        title: document.title.replace(/[A-Za-z]{1,2}\d{3,5}/i, "{plate}"),
      });
    },
    [tracking]
  );

  const location = useLocation();

  useEffect(() => {
    const timeout = setTimeout(trackLocation, undefined, location);

    return () => clearTimeout(timeout);
  }, [location, trackLocation]);

  const timeout = useRef<number | null>(null);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(trackLocation, undefined, location);
    });

    return unlisten();
  }, [history, trackLocation]);

  useEffect(() => {
    tracking.appLoad();
  }, [tracking]);

  return <>{children}</>;
}
