import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Location } from "history";

export function useLoadOncePerLocation() {
  const location = useLocation();
  const prev = useRef<Location>();
  const [loadedLocation, setLoadedLocation] = useState<Location | null>(null);
  const [loadRequested, setLoadRequested] = useState<Location | null>(null);

  useEffect(() => {
    setLoadedLocation(loadRequested);
  }, [loadRequested]);

  useEffect(() => {
    setLoadedLocation(null);
  }, [location]);

  const load = useCallback(() => {
    if (prev.current !== location) {
      setLoadRequested(location);
      prev.current = location;
    }
  }, [location]);

  return {
    load,
    loadedLocation,
  };
}
