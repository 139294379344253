import React, { ReactNode, useState } from "react";
import FlyoutModal from "@teamscheme/flyout-modal";
import InfoButton from "@teamscheme/info-button";

export default function InfoButtonModal({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <InfoButton
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
        open={isOpen}
      />
      <FlyoutModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {children}
      </FlyoutModal>
    </>
  );
}
