import React, {
  useEffect,
  useContext,
  useState,
  createContext,
  ReactNode,
} from "react";
import { useAuth, useAuthorizationHeader } from "./AuthProvider";
import { ApiError, useApiClient } from "../api/ApiClient";
import { captureException } from "@sentry/core";
import { useSentryTag, useTrackContext } from "../shared/Tracking";

export interface ConsentViewModel {
  id: string;
  consented?: boolean;
}

export interface Customer {
  customerNumber: string;
  customerId: string;
  hasMembership: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  consents: ConsentViewModel[];
}

export interface ICustomerContext {
  isLoggedIn: boolean;
  isLoading: boolean;
  result: Customer | null;
  error: ApiError | null;
}

export const CustomerContext = createContext<ICustomerContext | null>(null);

export function useCustomer() {
  const context = useContext(CustomerContext);

  if (!context) throw new Error("No CustomerContext found!");

  return context;
}

function getNumberDisplayName(customer: Customer) {
  return `${customer.hasMembership ? "Medlemsnr." : "Kundenr."}. ${
    customer.customerNumber
  }`;
}

export function getShortDisplayName(customer: Customer) {
  return customer.firstName || customer.email || getNumberDisplayName(customer);
}

export function getFullDisplayName(customer: Customer) {
  return (
    [customer.firstName, customer.lastName].filter((p) => !!p).join(" ") ||
    customer.email ||
    getNumberDisplayName(customer)
  );
}

export default function CustomerProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { isAuthenticated } = useAuth();
  const api = useApiClient();
  const authHeader = useAuthorizationHeader();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [result, setResult] = useState<Customer | null>(null);

  useEffect(() => {
    async function getCustomer() {
      setIsLoading(true);

      try {
        const customer = await api.send(
          "GET",
          "customer",
          undefined,
          authHeader
        );

        setResult(customer as Customer);
      } catch (error) {
        captureException(error);
        setError(error as ApiError);
      } finally {
        setIsLoading(false);
      }
    }

    if (!isAuthenticated) return;

    getCustomer();
  }, [api, isAuthenticated, authHeader]);

  useTrackContext("customerNumber", result?.customerNumber);
  useTrackContext("customerId", result?.customerId);
  useSentryTag("hasMembership", result?.hasMembership?.toString());
  useTrackContext("hasMembership", result?.hasMembership?.toString());

  const context = {
    isLoggedIn: isAuthenticated,
    isLoading,
    result,
    error,
  };

  return (
    <CustomerContext.Provider value={context}>
      {children}
    </CustomerContext.Provider>
  );
}
