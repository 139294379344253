import {
  createContext,
  useContext,
  useCallback,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useApiClient, ApiError } from "../api/ApiClient";
import {
  AccidentReportViewModel,
  AccidentReportViewModelA,
  AccidentReportViewModelB,
  VehicleKey,
} from "@accident-statement/schema";
import { makeCancelable, Cancelable } from "../shared/utils/cancelable";
import { captureException } from "@sentry/browser";
import ErrorScreen, { NotFoundPage } from "../shared/screens/ErrorScreen";
import { StaticLoadingScreen } from "../shared/screens/LoadingScreen";
import { useSentryTag, useTrackContext } from "../shared/Tracking";
import { getSplitReportLocation } from "./locked-report/SplitReportLocation";
import { useHistory } from "react-router-dom";

export function getReportTitle(report: AccidentReportViewModel) {
  return `${new Date(report.created).toLocaleDateString()}`;
}

type ReportA = { data: AccidentReportViewModelA; party: VehicleKey.A };
type ReportB = { data: AccidentReportViewModelB; party: VehicleKey.B };
type Report = { date: Date } & (ReportA | ReportB);

export interface IReportContext {
  party: VehicleKey;
  report: AccidentReportViewModel;
  updateReport: Dispatch<SetStateAction<AccidentReportViewModel>>;
  loaded: Date;
}

const ReportContext = createContext<IReportContext | null>(null);

export default ReportContext;

export function useReportContext() {
  const context = useContext(ReportContext);

  if (!context) throw new Error("No ReportContext found!");

  return context;
}

export function useReport() {
  return useReportContext().report;
}

export function useParty() {
  return useReportContext().party;
}

export function ReportProvider({
  children,
  token,
}: {
  children: ReactNode;
  token: string;
}) {
  const [loading, setLoading] = useState(!!token);
  const [loaded, setLoaded] = useState<Report | null>(null);
  const [loadError, setLoadError] = useState<ApiError | null>(null);
  const client = useApiClient();
  const history = useHistory();

  const updateReport = useCallback(
    (action: SetStateAction<AccidentReportViewModel>) =>
      setLoaded((prev) => {
        if (!prev) throw new Error("Cannot update report before it is loaded!");

        const data = typeof action === "function" ? action(prev.data) : action;

        return {
          ...prev,
          date: new Date(),
          data: data as any,
        };
      }),
    []
  );

  useSentryTag("party", loaded?.party);
  useTrackContext("party", loaded?.party);
  useSentryTag("reportId", loaded?.data.id);
  useTrackContext("reportId", loaded?.data.id);

  const missingReport = !loaded;

  const onReportLoaded = useCallback(function (
    report: AccidentReportViewModel
  ) {
    const x =
      "tokenA" in report
        ? ({ data: report, party: VehicleKey.A } as ReportA)
        : ({ data: report, party: VehicleKey.B } as ReportB);
    setLoaded({ ...x, date: new Date() });
  },
  []);

  useEffect(() => {
    async function loadReport(
      promise: Promise<AccidentReportViewModel | null>
    ) {
      setLoading(true);
      try {
        const report = await promise;
        console.log("loaded report", report);
        if (report) onReportLoaded(report);
        else setLoaded(null);
        setLoading(false);
      } catch (error) {
        const apiError = error as { isCanceled: true } | ApiError;
        if (!("isCanceled" in apiError)) {
          if (apiError.status === 409) {
            const { split } = apiError.result as { split?: boolean };
            if (split) {
              const location = getSplitReportLocation(token);
              history.push(location);
            }
          } else if (apiError.status !== 400) {
            setLoadError(apiError);
          } else {
            setLoaded(null);
          }
          setLoading(false);
          captureException(apiError);
        }
      }
    }
    let cancelablePromise: Cancelable<AccidentReportViewModel | null>;
    if (!!token && missingReport) {
      cancelablePromise = makeCancelable<AccidentReportViewModel | null>(
        client.get(`report/token/${token}`)
      );
      loadReport(cancelablePromise.promise);
    } else {
      setLoading(false);
    }
    return () => {
      if (cancelablePromise) cancelablePromise.cancel();
    };
  }, [token, missingReport, client, onReportLoaded, history]);

  if (loadError) {
    return <ErrorScreen />;
  }

  if (loading) {
    return <StaticLoadingScreen text="Henter skademelding" />;
  }

  if (!loaded) {
    return <NotFoundPage />;
  }

  return (
    <ReportContext.Provider
      value={{
        report: loaded.data,
        party: loaded.party,
        loaded: loaded.date,
        updateReport,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}

export function useVehicleReport() {
  const report = useReport();
  const party = useParty();
  const vehicleReport =
    party === VehicleKey.A ? report.vehicleA : report.vehicleB;

  return vehicleReport;
}

export function usePageTwo() {
  const report = useReport();
  const vehicleReport =
    "pageTwoA" in report ? report.pageTwoA : report.pageTwoB;

  return vehicleReport;
}
