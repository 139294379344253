import React, { forwardRef, useMemo } from "react";
import styled from "styled-components";
import { ITheme, onDesktop, onMinWidthXtraLarge } from "@teamscheme/theme";
import { createReturnPath } from "../../membership/MembershipPaymentPage";
import StaticPage, { BackLink, StaticPageProps } from "./StaticPage";
import Card, { CARD_PADDING_EM } from "../layout/Card";
import { ReportToken } from "../../api/ReportClient";
import { PageRef } from "../layout/Page";

function staticHeroPageSizes(theme: ITheme) {
  return {
    paddingTopHeroDesktop: theme.spacing.space20,
    paddingTopHeroLargeDesktop: theme.spacing.space80,
    heightH1: theme.fontStyle.header1["font-size"],
    heightBackLink: "1.5rem",
    textPadding: theme.spacing.space24,
  };
}

const Hero = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.space48};

  ${({ theme }) => onDesktop`
    padding-top: ${staticHeroPageSizes(theme).paddingTopHeroDesktop};
  `}
  ${({ theme }) => onMinWidthXtraLarge`
    padding-top: ${staticHeroPageSizes(theme).paddingTopHeroLargeDesktop};
  `};
`;

const HeroContent = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.skeleton.maxWidth};

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  ${({ theme }) => onDesktop`
    padding: 0 ${theme.spacing.space24};
    align-items: flex-start;
  `};
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  background: ${({ theme }) => theme.nafColor.primary.dew};
  height: 50%;
  max-height: 50vh;
  width: 100%;

  ${({ theme }) => {
    const { paddingTopHeroDesktop, heightH1, textPadding, heightBackLink } =
      staticHeroPageSizes(theme);
    return onDesktop`
        height: calc(
          ${paddingTopHeroDesktop} +
          ( ${textPadding} * 2 ) +
          ${heightH1} +
          ${heightBackLink}
        );
      `;
  }}

  ${({ theme }) => {
    const {
      paddingTopHeroLargeDesktop,
      heightH1,
      textPadding,
      heightBackLink,
    } = staticHeroPageSizes(theme);
    return onMinWidthXtraLarge`
        height: calc(
          ${paddingTopHeroLargeDesktop} + ( ${textPadding} * 2 ) + ${heightH1} + ${heightBackLink}
        );
      `;
  }}
`;

const BecomeMemberTitle = styled.h1`
  margin: 0;
  padding: ${({ theme }) => theme.spacing.space24} 0 0;

  ${({ theme }) => onDesktop`
    padding: ${theme.spacing.space24} ${theme.spacing.space48} 0
  `}
`;

const StyledBackLink = styled(BackLink)`
  padding: ${({ theme }) => theme.spacing.space24} 0 0;

  ${({ theme }) => onDesktop`
    padding: ${theme.spacing.space24} ${theme.spacing.space48} 0
  `}
`;

const FoldCard = styled(Card)`
  margin: 0;
  padding: 0 ${CARD_PADDING_EM}em;
  ${onDesktop`
    display: inline-block;
  `}
`;

interface StaticHeroPageInnerProps extends StaticPageProps {
  backTo: string;
  title: string;
}

export const StaticHeroPageInner = styled(
  forwardRef<PageRef, StaticHeroPageInnerProps>(function (
    { children, greenHeader = true, title, footer = false, backTo, ...rest },
    ref
  ) {
    return (
      <StaticPage greenHeader footer {...rest} ref={ref}>
        <Hero>
          <HeroContent>
            <FoldCard>
              <StyledBackLink backTo={backTo} />
              <BecomeMemberTitle>{title}</BecomeMemberTitle>
            </FoldCard>
            {children}
          </HeroContent>
          <HeroBackground />
        </Hero>
      </StaticPage>
    );
  })
)`
  padding: 0;
`;

interface StaticHeroPageProps extends Omit<StaticHeroPageInnerProps, "backTo"> {
  reportToken?: ReportToken;
  returnTo: string;
}

const StaticHeroPage = styled(
  forwardRef<PageRef, StaticHeroPageProps>(function (
    { reportToken, returnTo, ...rest },
    ref
  ) {
    const backTo = useMemo(
      () => createReturnPath(returnTo, reportToken),
      [returnTo, reportToken]
    );
    return <StaticHeroPageInner ref={ref} backTo={backTo} {...rest} />;
  })
)``;

export default StaticHeroPage;
