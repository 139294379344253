import React, {
  ComponentType,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ReportToken } from "../api/ReportClient";
import { Button, ButtonProps } from "@teamscheme/button";
import { ExternalTextLink } from "@teamscheme/text-link";
import Card from "../shared/layout/Card";
import { PageContent } from "../shared/layout/Page";
import ErrorScreen from "../shared/screens/ErrorScreen";
import { StaticLoadingScreen } from "../shared/screens/LoadingScreen";
import StaticPage from "../shared/static-pages/StaticPage";
import StaticRoutes from "../shared/static-pages/StaticRoutes";
import { useMembershipServiceClient } from "./MembershipServiceClient";
import { ApiError } from "../api/ApiClient";
import {
  OrderOrchestrationStatus,
  OrderPaymentStatus,
  PostPaymentOrderResponse,
} from "@dtp/membership-service-types";
import { useDocumentTitle } from "../shared/DocumentTitle";
import { captureException } from "@sentry/browser";
import { ILocalStoragePaymentDetails } from "./BecomeMemberFormPage";
import { useAuth } from "../auth/AuthProvider";
import { useCustomer } from "../auth/CustomerProvider";

export default function MembershipPaymentPage() {
  const { stateId: paymentKey } = useParams<{ stateId: string }>();
  const membershipServiceClient = useMembershipServiceClient();
  const [result, setResult] = useState<PostPaymentOrderResponse | null>(null);
  const [isPolling, setIsPolling] = useState(false);
  const [pollError, setPollError] = useState<ApiError | null>(null);
  const paymentObject = useMemo(() => {
    const rawPaymentObject = localStorage.getItem(paymentKey);
    return rawPaymentObject
      ? (JSON.parse(rawPaymentObject) as ILocalStoragePaymentDetails)
      : null;
  }, [paymentKey]);

  const shouldPoll =
    !pollError &&
    (result === null ||
      (result?.paymentOrderStatus === OrderPaymentStatus.PAID &&
        result?.status !== OrderOrchestrationStatus.DONE));

  useEffect(() => {
    if (paymentObject && shouldPoll) {
      const interval = setInterval(async () => {
        setIsPolling(true);

        try {
          const pollingResult =
            await membershipServiceClient.send<PostPaymentOrderResponse>(
              "GET",
              `v2/products/payment/${paymentObject.orchestrationId}`
            );
          setResult(pollingResult);
          setPollError(null);
        } catch (e) {
          const error = e as ApiError;
          captureException(error);
          setPollError(error);
        } finally {
          setIsPolling(false);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [shouldPoll, paymentObject, membershipServiceClient]);

  const { refetchToken: refreshAuth } = useAuth();
  const customer = useCustomer();

  useEffect(() => {
    if (
      customer.result &&
      !customer.result.hasMembership &&
      result?.status === OrderOrchestrationStatus.DONE
    ) {
      refreshAuth();
    }
  }, [customer.result, refreshAuth, result]);

  if (!paymentObject || pollError)
    return (
      <ErrorScreen
        message={
          <span>
            Vi klarte ikke hente statusen på din bestilling akkurat nå. Vi
            sender deg en e-post når medlemskapet blir aktivert. Last siden på
            nytt for å prøve på nytt.
          </span>
        }
      />
    );
  if (result && result?.paymentOrderStatus !== OrderPaymentStatus.PAID) {
    return (
      <MembershipPaymentAbortedPage
        result={result}
        paymentObject={paymentObject}
      />
    );
  }
  if (isPolling || result?.status !== OrderOrchestrationStatus.DONE) {
    const text = !result
      ? "Henter bestilling"
      : result.status === OrderOrchestrationStatus.WAITING_SLOW
      ? "Aktivering av medlemskap tar litt lenger tid enn vanlig, vennligst vent"
      : result.status === OrderOrchestrationStatus.RUNNING
      ? "Henter betalingsstatus"
      : "Aktiverer medlemskap";
    return <StaticLoadingScreen text={text} />;
  }

  return (
    <MembershipActivatedPage
      orderResponse={result}
      paymentObject={paymentObject}
    />
  );
}
const MembershipStatusPage = styled(function ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) {
  useDocumentTitle(title);

  return (
    <StaticPage footer>
      <Card>
        <PageContent>
          <h1>{title}</h1>
          {children}
        </PageContent>
      </Card>
    </StaticPage>
  );
})`
  flex: 1 0 auto;

  p:last-child {
    margin: 0;
  }
`;

const StyledPaymentButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing.space12};
  margin-bottom: ${({ theme }) => theme.spacing.space12};
` as ComponentType<ButtonProps>;

function MembershipPaymentAbortedPage({
  result,
  paymentObject,
}: {
  result: PostPaymentOrderResponse;
  paymentObject: ILocalStoragePaymentDetails;
}) {
  return (
    <MembershipStatusPage title="Betalingen ble avbrutt">
      <p>{result.summary.orderSummaries[0].productName} ble ikke aktivert. </p>
      {!result.paymentOrderUrl ||
      result.status === OrderOrchestrationStatus.TIMEOUT ? (
        <Button variant="primary" linkTo={getReturnPath(paymentObject)}>
          Tilbake til skademeldingen
        </Button>
      ) : (
        <>
          <p>
            Du kan prøve å betale på nytt, eller gå tilbake til skademeldingen.
          </p>
          <StyledPaymentButton variant="primary" href={result.paymentOrderUrl}>
            Til betaling
          </StyledPaymentButton>
          <Button variant="outline" linkTo={getReturnPath(paymentObject)}>
            Gå tilbake
          </Button>
        </>
      )}
    </MembershipStatusPage>
  );
}

function MembershipActivatedPage({
  orderResponse,
  paymentObject,
}: {
  orderResponse: PostPaymentOrderResponse;
  paymentObject: ILocalStoragePaymentDetails;
}) {
  return (
    <MembershipStatusPage title="Medlemskap aktivert">
      <h3>Velkommen som medlem, {orderResponse.summary.firstName}!</h3>
      <p>
        Du har nå {orderResponse.summary.orderSummaries[0].productName}. Ditt
        medlemsnummer er <b>{orderResponse.summary.customerNumber}</b>. <br />
        Med{" "}
        <ExternalTextLink
          href={`https://medlemsapp.naf.no/last-ned?utm_source=skademelding&utm_medium=internett&utm_campaign=kvittering_nytt_medlemskap`}
        >
          NAF medlemsapp
        </ExternalTextLink>{" "}
        får du tilgang til ditt digitale medlemskort og dine medlemsfordeler.
        Hvis du ønsker å gjøre endringer i medlemskapet ditt, kan du logge inn
        på Mitt NAF eller ta kontakt med NAF kundeservice.
      </p>
      <Button variant="primary" linkTo={getReturnPath(paymentObject)}>
        Gå tilbake
      </Button>
    </MembershipStatusPage>
  );
}

export function createBecomeMemberPath(
  returnTo: string,
  reportToken?: ReportToken
) {
  const base = `${StaticRoutes.becomeMember.path}?returnTo=${encodeURIComponent(
    returnTo
  )}`;

  if (reportToken) {
    return `${base}&token=${reportToken.value}&party=${reportToken.party}`;
  }

  return base;
}

export function createReturnPath(returnTo: string, reportToken?: ReportToken) {
  return reportToken ? `/t/${reportToken.value}${returnTo}` : returnTo;
}

export function getReturnPath(paymentObject: ILocalStoragePaymentDetails) {
  return createReturnPath(paymentObject.returnTo, paymentObject.reportToken);
}
