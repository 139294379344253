import { ComponentType, ReactNode } from "react";

export default function Providers({
  children,
  providers,
}: {
  children: ReactNode;
  providers: ComponentType<{ children: ReactNode }>[];
}) {
  return providers.reduceRight(
    (acc, Provider) => <Provider>{acc}</Provider>,
    <>{children}</>
  );
}
