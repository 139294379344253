import React from "react";
import styled from "styled-components";

const InputDescriptionInner = styled.div`
  color: ${({ theme }) => theme.nafColor.signature.black};

  > p,
  > ul {
    line-height: 1.5;
    margin-top: 0;
    font-weight: lighter;
    letter-spacing: 0px;
  }
`;

type InputDescriptionProps = {
  description: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function InputDescription({
  description,
  ...props
}: InputDescriptionProps) {
  return (
    <InputDescriptionInner
      {...props}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
}
