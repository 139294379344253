import { createContext, useContext } from "react";

export interface IProgressBarContext {
  setProgress(progress: number): void;
}

export const ProgressBarContext = createContext<IProgressBarContext | null>(
  null
);

export function useProgressBarContext() {
  const context = useContext(ProgressBarContext);
  if (!context) throw new Error("No ProgressBarContext found!");
  return context;
}
