import { EncodedSketch } from './Sketch';
import { SigningMethod, SignatureStatus } from './AccidentReportViewModel';
import { Mps, OldMps } from './Mps';

export type DateString = string & { readonly DateString: unique symbol };

export interface VehicleData {
  licensePlateNumber?: string;
  manufactureName?: string;
  modelName?: string;
  registrationCountry?: string;
}

export interface TrailerData extends VehicleData {
  hasTrailer?: boolean;
}

export interface ContactInfo {
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: DateString;
  address?: string;
  postalCode?: string;
  county?: string;
  country?: string;
}

export interface InjureeContactInfo extends ContactInfo {
  gender?: Gender;
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export interface AccidentQuestions {
  vehicleStill?: string;
  vehicleStarting?: string;
  wasStopping?: boolean;
  exitingParking?: boolean;
  enteringParking?: boolean;
  enteringRoundabout?: boolean;
  exitingRoundabout?: boolean;
  hitFromBehind?: boolean;
  drivingParallelLane?: boolean;
  changedLane?: boolean;
  wasPassing?: boolean;
  turnedRight?: boolean;
  turnedLeft?: boolean;
  wasBackingUp?: boolean;
  enteredWrongDirectionLane?: boolean;
  cameFromRightAtIntersection?: boolean;
  ignoredRightOfWaySignalOrRedLight?: boolean;
}

export interface Driver {
  driversLicenseNumber?: string;
  driversLicenseClass?: string;
  issueDate?: DateString;
  expirationDate?: DateString;
  gender?: Gender;
}

export interface Insurance {
  companyName?: string;
  agreementNumber?: string;
  comprehensiveInsurance?: boolean;
  greenCardNumber?: string;
  greenCardExpirationDate?: DateString;
}

export interface Insuree {
  sameAsDriver?: boolean;
  vatResponsible?: boolean;
  gender?: Gender;
}

export type AccidentQuestionValue =
  | { booleanValue: boolean }
  | { choiceValue: string };

export enum CollisionTouchType {
  None = 'None',
  Car = 'Car',
  MC = 'MC',
  Trailer = 'Trailer',
}

export interface CollisionTouch {
  type?: CollisionTouchType;
  x?: number;
  y?: number;
}

export interface TextSection {
  text?: string;
}

export enum VehicleKey {
  A = 'A',
  B = 'B',
}

export type VehicleKeyOrNone = VehicleKey | 'None';

export interface AttachmentFile {
  id: string;
  name: string;
  created: DateString;
  contentLength: number;
  contentType: string;
}

export interface AttachmentsInfo {
  files: AttachmentFile[];
  prepared?: AttachmentFile;
  totalContentLength: number;
}

export interface Witness {
  hadWitness?: boolean;
  phone?: string;
  fullName?: string;
  address?: string;
  postalCode?: string;
  county?: string;
  passenger?: VehicleKey;
}

export interface VehicleFieldSections {
  vehicleData?: VehicleData;
  trailerData?: TrailerData;
  driverContactInfo?: ContactInfo;
  insureeContactInfo?: ContactInfo;
  driver?: Driver;
  insurance?: Insurance;
  insuree?: Insuree;
  collisionTouch?: CollisionTouch;
  damageDescription?: TextSection;
  comments?: TextSection;
  accidentQuestions?: AccidentQuestions;
  attachments?: AttachmentsInfo;
  witness?: Witness;
  status?: VehicleReportStatus;
}

interface VehicleReportMeta {
  vehicleKey: VehicleKey;
}

export interface Vehicle extends VehicleFieldSections {
  meta: VehicleReportMeta;
}

export interface AccidentDescription {
  date?: DateString;
  location?: string;
  coordinates?: Coordinates;
  country?: string;
  county?: string;
  postalCode?: string;
  materialDamageOtherThanInvolvedVehicles: boolean;
  damageToNonVehicleObjects: boolean;
  injury: boolean;
}

export interface Coordinates {
  latitude: string;
  longitude: string;
}

export interface ReportingParty {
  vehicleKey: VehicleKey;
  phone: string;
  token: string;
}

export interface EntityBase {
  updated: DateString;
}

export interface S3Location {
  bucket: string;
  key: string;
}

export enum DocumentSignatureSectionType {
  Manual = 'Manual',
  Digital = 'Digital',
}

export interface DocumentInfo extends EntityBase {
  hash: string;
  pdfLocation: S3Location;
  previewLocation: S3Location;
  signatureSectionType: DocumentSignatureSectionType;
  created: DateString;
}

export interface SignicatPackage {
  packageId: string;
  archivedDocumentDeleted?: DateString;
}

export interface SignicatReference {
  artifact: string;
  requestId: string;
  taskIdA: string;
  taskIdB: string;
  useArchiveService: boolean;
}

export interface SignatureInfo extends EntityBase {
  created: DateString;
  hash: string;
  postbackToken: string;
  clientReference: string;
  signingMethod: SigningMethod;
  initiator: VehicleKey;
  unsignedDocument: DocumentInfo;
  signedDocument: DocumentInfo;
  signatureStatusA: SignatureStatus;
  signatureStatusUpdatedA?: DateString;
  signatureStatusB: SignatureStatus;
  signatureStatusUpdatedB?: DateString;
  signedProcessCompleted?: DateString;
  signedProcessStarted?: DateString;
  unsignedExpiration?: DateString;
  signatureStatusUpdated?: DateString;
  signatureStatus: SignatureStatus;
  signicatPackage?: SignicatPackage;
  signicatReference?: SignicatReference;
}

export interface SupplementalInfo {
  startVelocity?: number;
  collisionVelocity?: number;
  speedLimit?: number;
  surface: string[];
  condition: string[];
  weather: string[];
  temperature?: number;
  lightConditions: string[];
  lightsUsed: string[];
  equipmentUsed: string[];
  signal: string[];
  responsible: string[];
  businessVehicle?: boolean;
  driverIntoxicated?: boolean;
  substances: string[];
  bloodTested?: boolean;
  mileage?: number;
  roadAssistanceCalled?: boolean;
  roadAssistanceCompany?: string;
  materialDamageOtherThanInvolvedVehicles?: string;
  previousDamage?: boolean;
  previousDamageDescription?: string;
  policeCase?: boolean;
  policeDistrict?: string;

  // Extra fields for PDF only
  injuriesInOtherVehicle?: boolean;
  numInjuredPersonsInOtherVehicle?: number;
  confirmation?: boolean;
}

export interface Injuree {
  role: string[];
  securityEquipmentUsed: string[];
  treatment: string[];
  hospital?: string;
}

export interface MpsRequest {
  requestId: number;
  workshopId: string;
  requestStatus: OldMps.RequestStatus | null;
  status: Mps.RequestStatus | null;
  created: DateString;
  updated: DateString;
}

export interface VehicleReportStatus extends EntityBase {
  isComplete: boolean;
}

export interface PageTwoStatus {
  isComplete: boolean;
}

export interface PageTwo {
  status?: PageTwoStatus;
  supplementalInfo?: SupplementalInfo;
  damagedPropertyOwner?: ContactInfo;
  accidentDescription?: TextSection;
  injuree: Injuree[];
  injureeContactInfo: InjureeContactInfo[];
  document?: DocumentInfo;
}

export interface AccidentReport {
  id: string;
  created: DateString;
  vehicleA: Vehicle;
  vehicleB: Vehicle;
  partyA: ReportingParty;
  partyB?: ReportingParty;
  pageTwoA: PageTwo;
  pageTwoB: PageTwo;
  mpsRequestA?: MpsRequest;
  mpsRequestB?: MpsRequest;
  mpsRequestAttachmentA?: AttachmentsInfo;
  mpsRequestAttachmentB?: AttachmentsInfo;
  accidentDescription?: AccidentDescription;
  accidentDrawing: EncodedSketch;
  signature?: SignatureInfo;
  document?: DocumentInfo;
  splitSource?: SplitSource;
  splitDestinations: SplitDestination[];
}

export enum AccidentReportPage {
  PageOne = 'PageOne',
  PageTwo = 'PageTwo',
}

export interface SplitSource {
  reportId: string;
  party: VehicleKey;
  updated: DateString;
}

export interface SplitDestination {
  reportId: string;
  party: VehicleKey;
  updated: DateString;
}
