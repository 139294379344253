import React, { HTMLAttributes, ChangeEvent, cloneElement } from "react";
import { useState, useEffect } from "react";
import { useTracking } from "../Tracking";
import LabeledInput from "../forms/fields/LabeledInput";
import { TextLink } from "@teamscheme/text-link";
import styled from "styled-components";

export function useDebouncedQuery({
  queryEvent,
  queryString: initialQueryString = "",
}: {
  queryEvent: any;
  queryString?: string;
}) {
  const [queryString, setQueryString] = useState(initialQueryString);

  useEffect(() => {
    setQueryString(initialQueryString);
  }, [initialQueryString]);

  const [query, setQuery] = useState<string | null>(null);
  const tracking = useTracking();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newQuery = queryString.length >= 3 ? queryString : null;
      setQuery(newQuery);
    }, 250);

    return () => clearTimeout(timeout);
  }, [queryString]);

  useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => {
        tracking.event({
          ...queryEvent,
          label: query,
        });
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [queryEvent, tracking, query]);

  function clear() {
    setQuery(null);
    setQueryString("");
  }

  return {
    query,
    clear,
    inputProps: {
      value: queryString,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setQueryString(event.target?.value || ""),
    },
  };
}

export const FilterField = ({
  inputProps,
  label = "Søk i feltene",
  placeholder = "Let etter nøkkelord",
}: {
  inputProps: HTMLAttributes<HTMLInputElement>;
  label?: string;
  placeholder?: string;
}) => (
  <LabeledInput
    name="search"
    label={label}
    inputProps={{
      type: "search",
      placeholder,
      ...inputProps,
    }}
  />
);

export const NoResultsPlaceholder = ({
  query,
  clear,
}: {
  query: string;
  clear: () => void;
}) => (
  <div>
    <p>
      Fant ingen treff på "{query}". Prøv å søke på noe annet eller{" "}
      <TextLink onClick={clear}>nullstill søket</TextLink>.
    </p>
  </div>
);

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const MatchElement = styled.span`
  background-color: yellow;
`;

export function splitMatches(text: string, query: string): Match[] {
  if (!query) return [{ startIndex: 0, endIndex: text.length }];

  const re = new RegExp(escapeRegExp(query), "gi");

  const matches = [];

  let result;

  let prevIndex = re.lastIndex;
  while ((result = re.exec(text)) !== null) {
    if (result.index > prevIndex) {
      matches.push({ startIndex: prevIndex, endIndex: result.index });
    }
    matches.push({
      isMatch: true,
      startIndex: result.index,
      endIndex: re.lastIndex,
    });
    prevIndex = re.lastIndex;
  }

  if (matches.length) {
    matches.push({ startIndex: prevIndex, endIndex: text.length });
  }

  return matches;
}

interface Match {
  isMatch?: boolean;
  startIndex: number;
  endIndex: number;
}

function createMatchResult(text: string, match: Match, key: string | number) {
  const { isMatch, startIndex, endIndex } = match;
  const part = text.substring(startIndex, endIndex);
  return isMatch ? (
    <MatchElement key={`${key}::match::${startIndex}-${endIndex}`}>
      {part}
    </MatchElement>
  ) : (
    <span key={`${key}::part::${startIndex}-${endIndex}`}>{part}</span>
  );
}

export function searchString(text: string, query: string) {
  return splitMatches(text, query).map((match, i) =>
    createMatchResult(text, match, i)
  );
}

export function normalizeComponentTree(root: JSX.Element | string) {
  if (typeof root === "string") {
    return root;
  }
  if (!root.props.children) return "";
  if (typeof root.props.children === "string") {
    return root.props.children;
  }
  return root.props.children.map(normalizeComponentTree).join("");
}

function applyMatchesToHTML(root: HTMLElement, matches: Match[], i = 0) {
  if (!matches.length) return null;

  const str = (x: string) => ({
    result: matches
      .map(({ startIndex, endIndex, ...match }) => ({
        ...match,
        startIndex: startIndex - i,
        endIndex: endIndex - i,
      }))
      .filter((match) => match.endIndex >= 0 && match.startIndex <= x.length)
      .map((match) => {
        const { isMatch, startIndex, endIndex } = match;
        const part = x.substring(startIndex, endIndex);
        if (isMatch) {
          const span = document.createElement("span");
          span.innerText = part;
          span.className = "Match";
          return span;
        }
        return document.createTextNode(part);
      })
      .reduce((wrapper, child) => {
        wrapper.appendChild(child);
        return wrapper;
      }, document.createElement("span")),
    endIndex: i + x.length,
  });

  if (root.nodeType === Node.TEXT_NODE) return str((root as any).textContent);

  const result = root.cloneNode();

  for (const child of [...(root as any).childNodes]) {
    const next = applyMatchesToHTML(child, matches, i);

    if (!next) continue;

    const { result: newChild, endIndex } = next;

    i = endIndex;

    result.appendChild(newChild);
  }

  return {
    result,
    endIndex: i,
  };
}

function applyMatchesToComponents(
  root: JSX.Element | string,
  matches: Match[],
  i = 0
) {
  if (!matches.length) return null;

  const str = (x: string) => ({
    result: matches
      .map(({ startIndex, endIndex, ...match }) => ({
        ...match,
        startIndex: startIndex - i,
        endIndex: endIndex - i,
      }))
      .filter((match) => match.endIndex >= 0 && match.startIndex <= x.length)
      .map((match, j) => createMatchResult(x, match, `${j}/${i}`)),
    endIndex: i + x.length,
  });

  if (typeof root === "string") return str(root);

  if (!root.props.children) {
    return { result: root, endIndex: i };
  }

  let newChildren;

  if (typeof root.props.children === "string") {
    const { result, endIndex } = str(root.props.children);
    newChildren = result;
    i = endIndex;
  } else {
    newChildren = [];

    for (const child of root.props.children) {
      const next = applyMatchesToComponents(child, matches, i);

      if (!next) continue;

      const { result, endIndex } = next;

      i = endIndex;

      newChildren.push(result);
    }
  }

  const element = cloneElement(root, {
    ...root.props,
    children: newChildren,
    key: i,
  }) as any;

  return {
    result: element,
    endIndex: i,
  };
}

export function searchComponents(
  root: JSX.Element | string,
  query: string
): JSX.Element | null {
  const text = normalizeComponentTree(root);

  const matches = splitMatches(text, query);

  return matches.length
    ? applyMatchesToComponents(root, matches)?.result
    : null;
}

export function searchHTML(html: string, query: string): string {
  const wrapper = document.createElement("div");
  wrapper.innerHTML = html;
  const text = wrapper.innerText;

  const matches = splitMatches(text, query);

  return matches.length
    ? (applyMatchesToHTML(wrapper, matches)?.result as any | null)?.innerHTML
    : null;
}

export function search(text: JSX.Element | string, query: string) {
  if (typeof text === "string") {
    const result = searchString(text, query);

    return result.length ? <span>{result}</span> : null;
  }

  const result = searchComponents(text, query);

  return result;
}
