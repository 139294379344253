import React, { ReactNode } from "react";
import { TITLE_POSTFIX, useDocumentTitle, TITLE_SEP } from "../DocumentTitle";
import { PageContent } from "../layout/Page";
import { Button } from "@teamscheme/button";
import Card from "../layout/Card";
import StaticPage from "../static-pages/StaticPage";

export function LoadErrorPage({
  children,
  title,
}: {
  children: ReactNode;
  title: string | null;
}) {
  useDocumentTitle(title);

  return (
    <StaticPage footer>
      <PageContent>
        <Card>{children}</Card>
      </PageContent>
    </StaticPage>
  );
}

export function NotFoundPage() {
  return (
    <LoadErrorPage
      title={`404 ${TITLE_SEP} Finner ikke siden ${TITLE_POSTFIX}`}
    >
      <p>
        Siden du leter etter finnes ikke.
        <br />
        <br />
        Det kan være at siden rett og slett er utdatert eller at den har blitt
        flyttet.
        <br />
        Hvis du har fulgt en lenke fra SMS, sjekk at du brukte den siste du
        mottok.
      </p>
    </LoadErrorPage>
  );
}

export default function ErrorScreen({
  message = "Vi klarte dessverre ikke hente skademeldingen.",
}: {
  message?: ReactNode;
}) {
  return (
    <LoadErrorPage title={`Feil ${TITLE_POSTFIX}`}>
      <p>{message}</p>
      <Button variant="primary" onClick={() => window.location.reload()}>
        Last på nytt
      </Button>
    </LoadErrorPage>
  );
}
