import { nafColor } from "@nafcore/theme";
import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

const ErrorMessageStyle = css`
  color: ${nafColor.information.errorDark};
`;

interface ErrorMessageProps
  extends HTMLAttributes<HTMLParagraphElement & HTMLSpanElement> {
  component?: "p" | "span";
}

const ErrorMessage = styled((props: ErrorMessageProps) => {
  const { component: x, ...rest } = props;
  const component = x || "p";

  switch (component) {
    case "p":
      return <p {...rest} />;
    case "span":
      return <span {...rest} />;
    default:
      throw new Error(`Unrecognized component "${component}"`);
  }
})`
  ${ErrorMessageStyle}
`;

export default ErrorMessage;
