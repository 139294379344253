import { HTMLAttributes } from "react";
import styled from "styled-components";

export const PageCounter = styled(
  ({
    n,
    of,
    ...props
  }: { n: number; of: number } & HTMLAttributes<HTMLDivElement>) => (
    <div {...props}>
      Steg {n} av {of}
    </div>
  )
)`
  color: ${(props) => props.theme.nafColor.neutral.neutral4};

  & + h1 {
    margin-top: 0;
  }
`;

export interface PageProps extends HTMLAttributes<HTMLDivElement> {
  footer?: boolean;
}

export type PageRef = HTMLDivElement;

export const PageContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageContent = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.customBreakpoints.minDesktopWidth}px;
`;
