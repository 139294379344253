import { Dispatch, SetStateAction } from "react";
import { EVENTS, externalLinkEvent } from "../../Tracking";
import { MenuListItem } from "@teamscheme/header-elements";
import { IAuth0Context, useAuth } from "../../../auth/AuthProvider";
import { useCustomer } from "../../../auth/CustomerProvider";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MITT_NAF_URL from "../../../membership/MittNafExternalUrl";
import { ReportToken } from "../../../api/ReportClient";
import BECOME_MEMBER_URL from "../../../membership/BecomeMemberNafnoExternalUrl";

function createBecomeMemberProps(token: ReportToken | null): MenuListItem {
  const props: MenuListItem = {
    key: "BliMedlem",
    text: "Bli medlem",
    href: BECOME_MEMBER_URL,
    trackEvent: externalLinkEvent(BECOME_MEMBER_URL),
  };
  return props;
}

function createLoggedInHeaderProps(): MenuListItem {
  const props: MenuListItem = {
    key: "MittNAF",
    text: "Mitt NAF",
    icon: {
      icon: <AccountCircleOutlinedIcon className="icon" />,
    },
    href: MITT_NAF_URL,
    trackEvent: externalLinkEvent(MITT_NAF_URL),
  };
  return props;
}

function createLoginHeaderProps(auth: IAuth0Context): MenuListItem {
  const props: MenuListItem = {
    key: "LoggInn",
    text: "Logg inn",
    icon: {
      icon: <LockOutlinedIcon className="icon hide" />,
      hoverIcon: <LockOpenOutlinedIcon className="hoverIcon hide" />,
    },
    trackEvent: EVENTS.HEADER.LOG_IN,
    onClick: () => {
      auth.loginWithRedirect({
        targetUrl: `${window.location.pathname}${window.location.search}`,
      });
    },
  };
  return props;
}

function createLogoutHeaderProps(auth: IAuth0Context): MenuListItem {
  const props: MenuListItem = {
    key: "LoggUt",
    text: "Logg ut",
    trackEvent: EVENTS.HEADER.LOG_OUT,
    onClick: () => auth.logout(),
  };
  return props;
}

export function useAuthHeaderElements(token: ReportToken | null) {
  const auth = useAuth();
  const customer = useCustomer();
  if (customer.isLoading) {
    const loading: MenuListItem = {
      key: "HenterBruker",
      text: "Henter bruker",
      isLoading: true,
    };
    return [loading];
  }
  if (customer.result && customer.isLoggedIn) {
    const loggedIn = createLoggedInHeaderProps();
    const logout = createLogoutHeaderProps(auth);
    if (!customer.result.hasMembership) {
      return [loggedIn, logout, createBecomeMemberProps(token)];
    }
    return [loggedIn, logout];
  }
  return [createLoginHeaderProps(auth), createBecomeMemberProps(token)];
}

export function createFaqHeaderProps(
  isOpen: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>
): MenuListItem {
  const props: MenuListItem = {
    key: "FAQ",
    text: "Vanlige spørsmål",
    onClick: () => setOpen(!isOpen),
    trackEvent: EVENTS.HEADER.FAQ,
  };
  return props;
}
