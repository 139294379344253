import styled from "styled-components";
import { onDesktop } from "@teamscheme/theme";

const FormGroup = styled.div`
  margin-bottom: 32px;
`;

const spaceEm = 1;

export const FormGroupLine = styled.div`
  ${onDesktop`
    display: flex;
    margin-left: -${spaceEm / 2}em;
    margin-right: -${spaceEm / 2}em;
    ${FormGroup} {
      width: 50%;
      padding-left: ${spaceEm / 2}em;
      padding-right: ${spaceEm / 2}em;
    }
  `}
`;

export default FormGroup;
