import React, { HTMLAttributes, useContext, useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { onDesktop, onMinWidthLarge } from "@teamscheme/theme";
import { HeaderWithProgressContext } from "./HeaderWithProgress";
import { useWindowSize } from "../useWindowSize";
import {
  HeaderMenuButton,
  NavigationalHeaderElements,
  RoadAssistanceText,
} from "@teamscheme/header-elements";
import { SavingText } from "@teamscheme/saving-text";
import NafLogo from "../../logos/NafLogo";
import { MenuListItem } from "@teamscheme/header-elements";
import FlyoutMenu from "./FlyoutMenu";

export enum HeaderVariant {
  Static = "static",
  Flow = "flow",
}

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  variant?: HeaderVariant;
  elements: MenuListItem[];
}

const OuterHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${(props) => props.theme.skeleton.maxWidth};
`;

const HeaderWrapper = styled.div<{ variant: HeaderVariant }>`
  ${(props) =>
    css`
      padding: ${props.theme.spacing.space16} ${props.theme.spacing.space24};
    `}
  ${(props) => onDesktop`
    padding: ${props.theme.spacing.space24} ${props.theme.spacing.space24};
  `}
  ${(props) =>
    props.variant === HeaderVariant.Flow &&
    css`
      background: ${props.theme.nafColor.signature.white};
      ${onDesktop`
        padding: ${props.theme.spacing.space24} ${props.theme.spacing.space48};
      `}
    `};

  ${(props) =>
    props.variant === HeaderVariant.Static &&
    css`
      background: transparent;
    `};
`;

const flexRow = css`
  display: flex;
  flex-direction: row;
`;

const HeaderSection = styled.header`
  ${flexRow}
  align-items: center;
`;

const LogoHeaderSection = styled(HeaderSection)`
  justify-content: flex-start;
  flex: auto;
  ${onDesktop`
    width: 40%;
  `}
`;

const NavigationHeaderSection = styled(HeaderSection)`
  ${flexRow}
  justify-content: flex-end;
  ${onDesktop`
    width: 60%;
  `}
`;

const HeaderMenuWrapper = styled.div`
  display: none;
  ${onDesktop`
    display: inline-flex;
    & > *:nth-child(n+2){
      display: none;
    }
  `}
  ${onMinWidthLarge`
    & > *:nth-child(n+3){
      display: none;
    }
    & > *:not(:nth-child(n+3)){
      display: inline-flex;;
    }
  `};
`;

const RoadAssistance = styled(RoadAssistanceText)`
  margin-left: ${(props) => props.theme.spacing.space16};
  ${(props) => onDesktop`
    margin-left: ${props.theme.spacing.space32};
  `}
`;

const Header = styled(function ({ ...props }: HeaderProps) {
  const headerWithProgressContext = useContext(HeaderWithProgressContext);
  if (!headerWithProgressContext)
    throw new Error("No HeaderWithProgressContext found!");
  const { isSaving, hasSaved, saveError } = headerWithProgressContext;

  const headerVariant = props.variant || HeaderVariant.Flow;
  const isStatic = headerVariant === HeaderVariant.Static;
  const menuItems = props.elements;
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { width: screenWidth } = useWindowSize();
  const theme = useTheme();

  useEffect(() => {
    if (!!screenWidth) {
      if (
        (screenWidth < theme.customBreakpoints.minDesktopWidth &&
          menuItems.length < 1) ||
        (theme.customBreakpoints.minDesktopWidth < screenWidth &&
          theme.customBreakpoints.minWidthLarge > screenWidth &&
          menuItems.length < 2) ||
        (theme.customBreakpoints.minWidthLarge < screenWidth &&
          menuItems.length < 3)
      ) {
        setMenuIsOpen(false);
      }
    }
  }, [menuItems.length, screenWidth, theme.customBreakpoints.minDesktopWidth, theme.customBreakpoints.minWidthLarge]);

  return (
    <OuterHeader>
      <HeaderWrapper variant={headerVariant} {...props}>
        <LogoHeaderSection>
          <NafLogo size={isStatic ? 56 : 40} />
          {isStatic ? <RoadAssistance {...props} /> : null}
          {isStatic ? null : (
            <SavingText
              isSaving={isSaving}
              hasSaved={!isSaving && hasSaved}
              saveError={!!saveError}
            />
          )}
        </LogoHeaderSection>
        <NavigationHeaderSection>
          <HeaderMenuWrapper>
            <NavigationalHeaderElements elements={menuItems} />
          </HeaderMenuWrapper>
          <HeaderMenuButton
            className="menuButton"
            label={menuIsOpen ? "Lukk" : "Meny"}
            isOpen={menuIsOpen}
            onClick={() => setMenuIsOpen(!menuIsOpen)}
            menuLength={menuItems.length}
          />
        </NavigationHeaderSection>
        <FlyoutMenu
          menuItems={menuItems}
          isOpen={menuIsOpen}
          onClose={() => setMenuIsOpen(!menuIsOpen)}
          itemOnClick={() => setMenuIsOpen(!menuIsOpen)}
        />
      </HeaderWrapper>
    </OuterHeader>
  );
})`
  min-height: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export default Header;
