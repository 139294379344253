import { HTMLAttributes, useState } from "react";
import styled from "styled-components";
import FaqModal from "./FaqModal";
import Header, { HeaderVariant } from "./Header";
import { useAuthHeaderElements, createFaqHeaderProps } from "./HeaderObjects";

const StaticHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.nafColor.signature.white};
  width: 100%;
  z-index: 2;
`;

export default function StaticHeader(props: HTMLAttributes<HTMLDivElement>) {
  const authHeaderProps = useAuthHeaderElements(null);
  const [showFaq, setShowFaq] = useState(false);
  const faqProps = createFaqHeaderProps(showFaq, setShowFaq);

  return (
    <StaticHeaderWrapper {...props}>
      <FaqModal isOpen={showFaq} onClose={() => setShowFaq(false)} />
      <Header
        variant={HeaderVariant.Static}
        elements={[...authHeaderProps, faqProps]}
      />
    </StaticHeaderWrapper>
  );
}
