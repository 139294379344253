import { useCallback, useRef, useState } from "react";
import { AccidentReportPage } from "@accident-statement/schema";
import Header, { HeaderVariant } from "./Header";
import { useHeaderWithProgressContext } from "./HeaderWithProgress";
import styled from "styled-components";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import { createFaqHeaderProps, useAuthHeaderElements } from "./HeaderObjects";
import { MenuListItem } from "@teamscheme/header-elements";
import PreviewModal from "./PreviewModal";
import FaqModal from "./FaqModal";
import { useReportToken } from "../../../api/ReportClient";

const FlowHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.nafColor.signature.white};
  width: 100%;
  z-index: 2;
`;

export const ProgressBar = styled.header`
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 100%;
  transform-origin: 0 50%;

  background-color: ${(props) => props.theme.nafColor.primary.kart};
`;

export const ProgressWrapper = styled.div`
  transform-origin: 50% 0;
  transition: transform 0.15s linear;
  margin: 0 auto;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.nafColor.primary.dew};
  height: ${(props) => props.theme.spacing.space8};

  ${ProgressBar} {
    height: ${(props) => props.theme.spacing.space8};
  }
`;

export default function FlowHeader({ page }: { page: AccidentReportPage }) {
  const { progress } = useHeaderWithProgressContext();
  const token = useReportToken();
  const authHeaderProps = useAuthHeaderElements(token);
  const wrapper = useRef<HTMLDivElement | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showFaq, setShowFaq] = useState(false);
  const faqProps = createFaqHeaderProps(showFaq, setShowFaq);

  const previewButtonProps: MenuListItem = {
    key: "Forhåndsvisning",
    text: "Forhåndsvisning",
    icon: {
      icon: <EyeIcon className="icon" />,
    },
    onClick: useCallback(() => {
      setShowPreview(!showPreview);
    }, [showPreview]),
  };

  return (
    <>
      <FlowHeaderWrapper ref={wrapper}>
        <PreviewModal
          isOpen={showPreview}
          onClose={() => setShowPreview(false)}
          page={page}
        />
        <FaqModal isOpen={showFaq} onClose={() => setShowFaq(false)} />
        <Header
          variant={HeaderVariant.Flow}
          elements={[previewButtonProps, ...authHeaderProps, faqProps]}
        />
        <ProgressWrapper>
          <ProgressBar
            style={{
              transform: `scaleX(${progress})`,
            }}
          />
        </ProgressWrapper>
      </FlowHeaderWrapper>
    </>
  );
}

export function NoPreviewFlowHeader() {
  const { progress } = useHeaderWithProgressContext();
  const token = useReportToken();
  const authHeaderProps = useAuthHeaderElements(token);
  const wrapper = useRef<HTMLDivElement | null>(null);
  const [showFaq, setShowFaq] = useState(false);
  const faqProps = createFaqHeaderProps(showFaq, setShowFaq);

  return (
    <>
      <FlowHeaderWrapper ref={wrapper}>
        <FaqModal isOpen={showFaq} onClose={() => setShowFaq(false)} />
        <Header
          variant={HeaderVariant.Flow}
          elements={[...authHeaderProps, faqProps]}
        />
        <ProgressWrapper>
          <ProgressBar
            style={{
              transform: `scaleX(${progress})`,
            }}
          />
        </ProgressWrapper>
      </FlowHeaderWrapper>
    </>
  );
}
