import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/array/includes";
import "core-js/features/string/includes";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://39ff004f0e38423985f39258a6782694@o208856.ingest.sentry.io/5384083",
  environment: document.location.hostname,
  release: `naf-digital-skademelding@${process.env.REACT_APP_VERSION}`,
  debug: document.location.hostname.includes("localhost"),
});

// TODO: Enable StrictMode when react-tel-input-2 stops using UNSAFE_componentWIllREceiveProps
createRoot(document.getElementById("root")!).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
