import LoadingText from "@teamscheme/loading-text";
import { HTMLAttributes } from "react";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface LoadingBoxProps {
  text?: string;
}

const LoadingBox = styled(function ({
  text = "Laster",
  ...props
}: LoadingBoxProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <LoadingWrapper {...props}>
      <LoadingText text={text} />
    </LoadingWrapper>
  );
})``;

export default LoadingBox;
