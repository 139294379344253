import { DateString } from "./AccidentReport";

export namespace OldMps {
  export interface RequestStatus {
    request: RequestInformation;
    workshop: MpsWorkshop;
    url: string;
    offer?: MpsOffer;
  }

  export interface RequestInformation {
    id: number;
    offer_date?: DateString;
    created_at: DateString;
  }

  export interface MpsWorkshop {
    id: number;
    name: string;
    street: string;
    street2: string;
    city: string;
    postal: string;
  }

  export interface MpsOffer {
    totalPrice: number;
    offerPrice: number;
  }
}

export namespace Mps {
  export interface RequestStatus {
    id: number;
    contactName: string;
    email: string;
    phoneNumber: string;
    carRegistrationNumber: string;
    workshop: Workshop;
    insuranceCompany: number;
    status: MpsStatus;
    customerUrl: string;
    offer?: Offer;
    createdDate: DateString;
    updatedDate: DateString;
  }

  export interface Offer {
    price: string;
    pdfUrl?: string;
    estimationDays: number;
    updatedDate: DateString;
  }

  export interface Workshop {
    id: string;
    name: string;
    workingHours?: string;
    email: string;
    phoneNumber: string;
    city: string;
    street: string;
    zipCode: string;
  }

  export interface InsuranceCompany {
    id: string;
    name: string;
  }

  export enum MpsStatus {
    New = "New",
    InWork = "In work",
    OfferSent = "Offer sent",
    OfferAccepted = "Offer accepted",
    OfferRejected = "Offer rejected",
    Cancelled = "Cancelled",
    InspectionRequested = "Inspection requested",
    Completed = "Completed",
  }
}
