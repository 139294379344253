import styled from "styled-components";
import { HTMLAttributes } from "react";

export const CARD_PADDING_EM = 1;

export type CardProps = HTMLAttributes<HTMLDivElement>;

const Card = styled.div`
  background: ${({ theme }) => theme.nafColor.signature.white};
  margin-bottom: 1em;
  padding: ${CARD_PADDING_EM}em;
  border-radius: ${({ theme }) => theme.radius.s};
  & > h1,
  & > h2,
  & > h3 {
    margin-top: 0;
  }
`;

export default Card;
