import { useEffect, useMemo } from "react";
import { useReport } from "../report/ReportContext";
import { getReportTitle } from "../report/ReportContext";

/**
 * Adds a useEffect hook that updates the title, and reverts
 * to the original on cleanup.
 *
 * @param {string} title
 */
export function useDocumentTitle(title: string | null) {
  useEffect(() => {
    let oldTitle = document.title;

    if (title) document.title = title;

    return () => {
      document.title = oldTitle;
    };
  }, [title]);
}

export function useReportPageTitle(title: string | null) {
  const report = useReport();

  const formattedTitle = useMemo(() => {
    if (!title) return null;

    const reportTitle = getReportTitle(report);

    return `${title} ${TITLE_SEP} ${reportTitle} ${TITLE_POSTFIX}`;
  }, [report, title]);

  useDocumentTitle(formattedTitle);
}

export const TITLE_SEP = "\u2013";
export const TITLE_POSTFIX = `${TITLE_SEP} NAF Digital skademelding`;
